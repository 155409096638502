import { Heading, Modal, Spinner, VStack } from 'native-base'

export const SubmittingModalContent = ({
  modalHeaderText = 'Submitting Instructions...',
}: {
  modalHeaderText?: string
}) => {
  return (
    <Modal.Content
      justifyContent={'center'}
      alignItems={'stretch'}
      overflowY={'auto'}
      bg={'brand.warmWhite'}
      width={350}
      maxWidth={'90svw'}
      height={550}
      maxHeight={'95svh'}
    >
      <VStack space={4} alignItems={'center'} p={3} my={4}>
        <Heading textAlign={'center'}>{modalHeaderText}</Heading>
        <Spinner size={'lg'} color={'primary.300'} outlineStyle={'none'} />
      </VStack>
    </Modal.Content>
  )
}
