import { Box, Center, Link, Text } from 'native-base'

import { trackEvent } from '../utils/analytics-v2'

export default function Footer() {
  return (
    <Center
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        paddingTop: 25,
        paddingBottom: 32,
      }}
    >
      <Link
        href="https://shipveho.com/"
        onPress={() => trackEvent('navigate_to_main_site', { element: 'footer_link' })}
      >
        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '181px' }}>
          <Text style={{ color: '#5D5D5A', fontWeight: '500' }}>{`© ${new Date().getFullYear()} Veho Tech, Inc`}</Text>
        </Box>
      </Link>
    </Center>
  )
}
