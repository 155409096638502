import { useFormikContext } from 'formik'

import { Order } from '../../../types/graphql'
import { ModalContent } from '../Common/ModalContent'
import { AddressForm, AddressInputGroupType } from './AddressForm'

export interface IAddressFormModalContentProps {
  order: Order
  closeModal: () => void
}

export const AddressFormModalContent = ({ order, closeModal }: IAddressFormModalContentProps) => {
  const { isSubmitting, isValid, touched, handleSubmit } = useFormikContext<AddressInputGroupType>()

  return (
    <ModalContent
      headerMain={'Edit Address'}
      headerSecondary={''}
      submitText={'Save Address'}
      cancelText={'Cancel'}
      onSubmit={handleSubmit}
      closeModal={closeModal}
      disabled={isSubmitting || !isValid || !touched}
      loading={isSubmitting}
      submitButtonStyles={{ width: '100%' }}
    >
      <AddressForm order={order}></AddressForm>
    </ModalContent>
  )
}
