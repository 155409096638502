import { gql } from 'graphql-tag'

import { orderFields } from './orderFields'

export const GET_ORDER_BY_TRACKING_ID = gql`
  query getOrderByTrackingIdCWA($trackingId: String!) {
    order: getOrderByTrackingIdCWA(trackingId: $trackingId, includePickups: true) {
      ...OrderFields
    }
  }
  ${orderFields}
`
