import { useFormikContext } from 'formik'
import { useEffect } from 'react'

import { trackEvent } from '../../../utils/analytics-v2'
import { ModalContent } from '../Common/ModalContent'
import { PhoneFormType } from '../Phone/PhoneInput'
import { VerificationInput } from './VerificationInput'

export type VerificationModalContentProps = {
  resendAuth: () => void
  resendLoading: boolean
  closeModal: () => void
  disableSubmit: boolean
  disableResend: boolean
  modalHeaderText?: string
  modalBodyText?: string
  cancelText?: string
}

export const VerificationModalContent = ({
  resendAuth,
  resendLoading,
  closeModal,
  disableSubmit,
  disableResend,
  modalHeaderText = 'Confirm Instructions',
  modalBodyText = 'We need to verify your phone number to add delivery instructions.',
  cancelText,
}: VerificationModalContentProps) => {
  const { isSubmitting, handleSubmit } = useFormikContext<PhoneFormType>()

  useEffect(() => {
    trackEvent('view_verification', { verification_type: 'phone' })
  }, [])

  return (
    <ModalContent
      headerMain={modalHeaderText}
      headerSecondary={modalBodyText}
      submitText={'Submit'}
      onSubmit={handleSubmit}
      closeModal={closeModal}
      disabled={disableSubmit || isSubmitting}
      loading={isSubmitting}
      cancelText={cancelText}
    >
      <VerificationInput
        resendAuth={resendAuth}
        resendLoading={resendLoading}
        disableSubmit={disableSubmit}
        disableResend={disableResend}
      />
    </ModalContent>
  )
}
