import * as Sentry from '@sentry/react'

import { BREADCRUMB_TYPES } from './enums'

export interface ConsumerNoPii {
  consumerId: string
  agreedToTermsAndConditions?: boolean | null
  latestTermsVersionAgreedTo?: string | null
  submittedAppReviewDate: string | null
  firebaseId?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logSentryError = (err: any, context?: object) => {
  Sentry.captureException(err, {
    tags: { ...context },
  })
}

export const logSentryBreadcrumb = (message: string, type: BREADCRUMB_TYPES = BREADCRUMB_TYPES.manual, data = {}) => {
  try {
    Sentry.addBreadcrumb({
      message,
      type,
      ...data,
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    logSentryError(err)
  }
}
