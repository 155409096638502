import { Box, Image, Pressable } from 'native-base'
import { useContext } from 'react'
import { isMobile } from 'react-device-detect'

import { useGateValue } from '@statsig/react-bindings'

import SupportButton from '../atoms/SupportButton'
import { AppContext } from '../context/AppContextProvider'
import { trackEvent } from '../utils/analytics-v2'
import { useScreenSize } from '../utils/displayUtils'
import { STATSIG_FEATURE_GATES } from '../utils/enums'
import { LoginButton } from './Instructions/LoginButton'

export default function NavBar() {
  const screenSize = useScreenSize()
  const isMobileScreen = isMobile || screenSize.width < 1000
  const showNewMapUI = useGateValue(STATSIG_FEATURE_GATES.showNewMapUI)
  const { order } = useContext(AppContext)

  const handleLinkClick = (href: string) => {
    trackEvent('navigate_to_main_site', { element: 'logo' })
    window.open(href, '_self')
  }
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: showNewMapUI ? (isMobileScreen ? '100%' : '50%') : '100%',
        alignItems: 'center',
      }}
      px={3}
    >
      <Box display="flex" justifyContent="center">
        <Pressable
          id="vehoMarketing"
          onPress={() => {
            handleLinkClick('https://www.shipveho.com/')
          }}
        >
          <Image source={{ uri: 'veho-logo.svg' }} resizeMode="contain" size="md" alt="Logo" />
        </Pressable>
      </Box>
      {isMobile && !showNewMapUI && (
        <Box display="flex" justifyContent="center" mr={-4}>
          <SupportButton />
        </Box>
      )}
      {
        // hide login button until user has queried for an order
        showNewMapUI && order?.orderId?.length && order?.instructionEditsAllowed ? (
          <LoginButton orderHasPiiFields={order?.address?.street && order?.address?.name ? true : false} />
        ) : null
      }
    </Box>
  )
}
