import { Box, Button, HStack, Heading, Text, VStack } from 'native-base'
import { useContext } from 'react'

import PencilSimpleSVG from '../assets/PencilSimpleSVG'
import PlusSVG from '../assets/PlusSVG'
import { AuthContext } from '../context/AuthContextProvider'
import { Order } from '../types/graphql'
import { InstructionsRender } from './Instructions/Common/InstructionsRender'

interface IDeliveryAddressProps {
  order: Order
  editAddress?: () => void
  addInstructions?: () => void
  styleProps?: object
}

export const DeliveryAddress = ({ editAddress, addInstructions, order, styleProps }: IDeliveryAddressProps) => {
  const canEdit = !!(
    order?.address?.preferredLocation ||
    order?.address?.preferredPickupLocation ||
    order?.address?.instructions ||
    order?.address?.pickupInstructions ||
    order?.address?.securityCode ||
    order?.address?.attributes?.length
  )
  const hasPiiFields = order?.address?.name && order?.address?.street
  const { firebaseUser } = useContext(AuthContext)

  const EditButton = () => {
    const showEditButton =
      order?.instructionEditsAllowed &&
      order?.isOrderReroutableByConsumer &&
      order?.isOrderReroutable &&
      (firebaseUser ? order?.authedUserOwnsOrder : true)

    return showEditButton ? (
      <Button
        size="sm"
        width="113px"
        height="32px"
        borderRadius={100}
        bgColor="black"
        onPress={() => (editAddress ? editAddress() : null)}
      >
        {hasPiiFields ? 'Edit' : 'View Address'}
      </Button>
    ) : null
  }

  return (
    <Box
      style={{
        backgroundColor: 'white',
        paddingTop: '18px',
        paddingHorizontal: '21px',
        borderRadius: 24,
        marginTop: -40,
        ...styleProps,
      }}
    >
      <HStack justifyContent={'space-between'} pb={3} borderBottomWidth={'1px'} borderBottomColor={'#ECECE5'}>
        <VStack>
          <Heading fontSize={10} fontWeight={'700'} fontFamily={'bodyBold'}>
            DELIVERING TO:
          </Heading>
          {hasPiiFields && (
            <VStack>
              <Text fontSize={14} fontWeight={'400'}>
                {order?.address?.name}
              </Text>
              <Text fontSize={14} fontWeight={'400'}>
                {order?.address?.street}
              </Text>
              {order?.address?.apartment && (
                <Text fontSize={14} fontWeight={'400'}>
                  {order?.address?.apartment}
                </Text>
              )}
            </VStack>
          )}
          <Text fontSize={14} fontWeight={'400'}>
            {order?.address?.city}, {order?.address?.state} {order?.address?.zipCode}
          </Text>
        </VStack>
        <EditButton />
      </HStack>
      <VStack pt={3} pb={2}>
        <Heading fontSize={10} fontWeight={'700'} fontFamily={'bodyBold'}>
          DELIVERY INSTRUCTIONS
        </Heading>
        <VStack>{hasPiiFields && <InstructionsRender order={order as Order} />}</VStack>
        <Button
          onPress={() => (addInstructions ? addInstructions() : () => {})}
          size="sm"
          backgroundColor={'transparent'}
          width={'100%'}
        >
          <HStack alignItems={'center'}>
            {canEdit ? <PencilSimpleSVG /> : <PlusSVG />}
            <Text ml={1}>{canEdit ? 'Update Instructions' : 'Add Additional Instructions'}</Text>
          </HStack>
        </Button>
      </VStack>
    </Box>
  )
}
