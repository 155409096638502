import { useField } from 'formik'
import { FormControl, HStack, IInputProps, Input, Text, VStack } from 'native-base'
import React, { memo, useState } from 'react'
import { TextInputMask } from 'react-native-masked-text'

import { colors } from '../../../../utils/theme/configureTheme'

const MaskedTextInput = (props: IInputProps) => {
  const {
    autoComplete,
    autoFocus,
    hint,
    keyboardType,
    label,
    leftIcon,
    leftHintIcon,
    mask,
    name,
    optional,
    charLimit,
    charLimitCounter,
    errorMessageExternal,
  } = props
  const [, meta, helpers] = useField<string>(name)
  const [inputVal] = useState<string>(meta.value)

  return (
    <FormControl
      isInvalid={meta.error?.length || errorMessageExternal ? true : false}
      key={`${name}-form-control`}
      testID={`${name}-form-control`}
      bg={!props?.bg ? 'transparent' : props.bg}
      style={props.style}
    >
      <VStack alignItems={'stretch'} width={'100%'}>
        <HStack justifyContent={'space-between'} backgroundColor={colors.brand.warmWhite}>
          <HStack>
            {label && (
              <FormControl.Label
                key={`${name}-label`}
                testID={`${name}-label`}
                mt={0}
                _text={{
                  fontWeight: 600,
                  fontSize: 13,
                  lineHeight: 19.5,
                  color: colors.brand.softBlack,
                }}
              >
                {label}
              </FormControl.Label>
            )}
            {optional && (
              <FormControl.Label
                key={`${name}-optional-label`}
                testID={`${name}-optional-label`}
                ml={3}
                mt={0}
                _text={{
                  fontWeight: 700,
                  fontSize: 11,
                  color: props?.isDisabled ? colors.gray[200] : colors.brand.darkGrey,
                  alignSelf: 'center',
                  textTransform: 'uppercase',
                }}
                isDisabled={props?.isDisabled}
              >
                Optional
              </FormControl.Label>
            )}
          </HStack>
          <HStack>
            {charLimitCounter && (
              <FormControl.HelperText
                key={`${name}-char-limit-label`}
                testID={`${name}-char-limit-label`}
                _text={{ fontWeight: 600, fontSize: 12, color: colors.brand.darkGrey }}
              >
                {`${inputVal?.length ?? 0}/${charLimit}`}
              </FormControl.HelperText>
            )}
          </HStack>
        </HStack>
        <TextInputMask
          key={`${name}-mask-input`}
          type="custom"
          options={{
            mask,
          }}
          customTextInput={Input}
          customTextInputProps={{
            autoComplete,
            autoFocus,
            bg: colors.brand.white,
            _focus: { bg: colors.brand.white },
            size: 'lg',
            InputLeftElement: leftIcon,
            keyboardType,
            testID: `${name}-mask-input`,
            ...props,
          }}
          onChangeText={newValue => helpers.setValue(newValue)}
          value={meta.value}
          maxLength={charLimit}
        />
        {meta.error && !errorMessageExternal ? (
          <FormControl.ErrorMessage fontSize={12} testID={`${name}-error`}>
            {meta.error}
          </FormControl.ErrorMessage>
        ) : (
          hint && (
            <FormControl.HelperText
              mt={1}
              fontSize={13}
              color={'brand.darkGrey'}
              key={`${name}-hint`}
              testID={`${name}-hint`}
            >
              <HStack alignItems={'center'} backgroundColor={colors.brand.warmWhite} space={5}>
                {leftHintIcon}
                <Text fontSize={'inherit'} color={'inherit'} fontWeight={'inherit'} lineHeight={'inherit'}>
                  {hint}
                </Text>
              </HStack>
            </FormControl.HelperText>
          )
        )}
      </VStack>
    </FormControl>
  )
}

MaskedTextInput.defaultProps = {
  required: false,
}

export default memo(MaskedTextInput)
