import { User } from 'firebase/auth'
import { createContext, useCallback, useEffect, useMemo, useState } from 'react'

import client from '../apolloClient'
import { auth } from '../utils/firebase'

interface CWAUser {
  name: string
  consumerId: string
  agreedToTermsAndConditions: boolean
  latestTermsVersionAgreedTo: string
  submittedAppReviewDate: string
  firebaseId: string // Firebase Auth UID
}

interface IAuthContextValue {
  logout: () => void
  user?: CWAUser | null
  firebaseUser?: User | null
}

const AuthContextDefaultState: IAuthContextValue = {
  logout: () => {},
  user: null,
}

export const AuthContext = createContext(AuthContextDefaultState)

const isNotLocalDev = process.env.REACT_APP_STAGE !== 'local'

function AuthContextProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
  const [firebaseUser, setFirebaseUser] = useState<User | null>(null)
  const [user, setUser] = useState<CWAUser | null>(null)

  const handleSetFirebaseUser = useCallback((fbUser: User | null) => {
    setFirebaseUser(fbUser)
  }, [])

  useEffect(() => {
    // don't run this for localDev since we instantiate an empty Firebase Auth object
    if (auth && isNotLocalDev) {
      // listen for auth state changes from Firebase auth and update firebaseUser
      const unsubscribe = auth?.onAuthStateChanged(handleSetFirebaseUser)
      return unsubscribe
    }
  }, [handleSetFirebaseUser])

  const logout = useCallback(async () => {
    if (auth.currentUser) {
      await auth.signOut()
      setFirebaseUser(null)
      setUser(null)
      // force refetch to clear fields on order
      client.refetchQueries({ include: 'all' })
    }
  }, [])

  useEffect(() => {
    // end session if user closes tab
    const handleLogout = () => logout()
    window.addEventListener('pagehide', handleLogout)

    return () => window.removeEventListener('pagehide', handleLogout)
  }, [logout])

  const state = useMemo(() => {
    return {
      firebaseUser,
      user,
      logout,
    }
  }, [firebaseUser, user, logout])

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

export default AuthContextProvider
