import { gql } from 'graphql-tag'

export const addressFields = gql`
  fragment AddressFields on Address {
    addressId
    type
    name
    street
    apartment
    city
    state
    country
    zipCode
    attributes
    location {
      lat
      lng
    }
    instructions
    pickupInstructions
    preferredLocation
    preferredPickupLocation
    securityCode
    images {
      addressId
      createdAt
      imageType
      key
      meta
      tags
      location {
        lat
        lng
      }
      updatedAt
      imageUrl
    }
  }
`
