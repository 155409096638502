import { Text, View } from 'native-base'

import { AddressAttribute, Order, OrderServiceType } from '../../../types/graphql'
import { InstructionsBadge } from './InstructionsBadge'

export type InstructionsRenderProps = {
  order: Order
}

export const InstructionsRender = ({ order }: InstructionsRenderProps) => {
  let typeLabel, locationLabel, preferredLocation, instructions
  if (order.serviceType === OrderServiceType.Pickup) {
    typeLabel = 'Pickup from my '
    locationLabel = 'Collect from '
    preferredLocation = order.address.preferredPickupLocation
    instructions = order.address.pickupInstructions
  } else {
    typeLabel = 'Deliver to my '
    preferredLocation = order.address.preferredLocation
    locationLabel = preferredLocation === 'Property Staff' ? 'Leave with ' : 'Leave at '
    instructions = order.address.instructions
  }

  const hasDog = !!order.address.attributes?.find(attr => attr === AddressAttribute.HasDog)

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        rowGap: 4,
        columnGap: 1,
        alignItems: 'baseline',
        flexWrap: 'wrap',
      }}
    >
      <Text fontSize={12} lineHeight={24} flexWrap={'wrap'}>
        {!!order.address.type && (
          <>
            {typeLabel}
            <InstructionsBadge label={order.address.type} />
            {'. '}
          </>
        )}

        {!!preferredLocation && preferredLocation !== 'Not Set' && (
          <>
            {locationLabel}
            <InstructionsBadge label={preferredLocation} />
            {'. '}
          </>
        )}
        {!!order.address.securityCode && (
          <>
            {'My access code is '}
            <InstructionsBadge label={order.address.securityCode} />
            {'. '}
          </>
        )}
        {!!instructions && (
          <>
            {'And '}
            <InstructionsBadge label={instructions} />
          </>
        )}
        {hasDog && <InstructionsBadge label={'Beware of dog'} />}
      </Text>
    </View>
  )
}
