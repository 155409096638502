import { InMemoryCache } from '@apollo/client'

// Since we don't defined a `id` or `_id` field. See https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
const typePolicies = {
  Facility: {
    keyFields: ['facilityId'],
  },
}

export const cache = new InMemoryCache({ typePolicies })
