import { Box, Image, Pressable, Text, VStack } from 'native-base'
import { isMobile } from 'react-device-detect'

import { useGateValue } from '@statsig/react-bindings'

import { trackEvent } from '../utils/analytics-v2'
import { STATSIG_FEATURE_GATES } from '../utils/enums'

const Support = ({
  showMobile,
  maxWidth,
  maxHeight,
  height,
}: {
  showMobile: boolean
  maxWidth?: string
  maxHeight?: string
  height?: string
}) => {
  const showNewMapUI = useGateValue(STATSIG_FEATURE_GATES.showNewMapUI)
  const handleLinkClick = () => {
    trackEvent('contact_support', { method: 'sms' })
    window.location.href = `sms:68953`
  }

  const UpdatedSupportButton = () => {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: '20px',
          borderColor: '#E8E8E8',
          borderWidth: 1,
          padding: 24,
          backgroundColor: 'white',
        }}
        borderRadius={'24px'}
        maxWidth={maxWidth}
        maxH={maxHeight}
        height={height}
      >
        <Image
          source={{
            uri: 'support.svg',
          }}
          resizeMode="contain"
          alt="Support"
          size="md"
          mr={'32px'}
        />
        <VStack display={'flex'} alignItems={'flex-start'} flex={1}>
          <Text fontFamily={'bodyBold'} style={{ fontSize: 14, marginBottom: 4 }}>
            Need help?
          </Text>
          <Text marginBottom={2.5} style={{ fontSize: 14 }}>
            Text us at 68953 to connect with a support agent.
          </Text>
          <Pressable id="contact-support" onPress={() => handleLinkClick()}>
            <Box
              paddingX="16px"
              paddingY="6px"
              bg="black"
              rounded="lg"
              borderRadius={100}
              _text={{
                color: 'white',
                fontWeight: '600',
                fontSize: '14px',
              }}
            >
              Text Support
            </Box>
          </Pressable>
        </VStack>
      </Box>
    )
  }

  return (
    <>
      {isMobile || showMobile ? (
        <Pressable onPress={() => handleLinkClick()} id="contact-support">
          {showNewMapUI ? (
            UpdatedSupportButton()
          ) : (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                paddingTop: '20px',
                paddingBottom: '20px',
                borderColor: '#E8E8E8',
                borderWidth: 1,
                paddingLeft: '16px',
                backgroundColor: 'white',
              }}
              flex="1"
              rounded="md"
              textAlign="left"
              height="98px"
              marginX="16px"
            >
              <Box paddingRight="16px">
                <Image
                  source={{
                    uri: 'support.svg',
                  }}
                  resizeMode="contain"
                  alt="Support"
                  size="sm"
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-start"
                flex="1"
                paddingRight="20px"
              >
                <Text fontFamily={'bodyBold'} style={{ fontSize: 14, marginBottom: 4 }}>
                  Need help with your delivery?
                </Text>
                <Text style={{ fontSize: 14 }}>Text us at 68953 to connect with a support agent.</Text>
              </Box>
              <Box marginRight="26px" alignItems="center" justifyContent="center">
                <img src="/chevron_right.svg" alt="logo" width={10} height={16} />
              </Box>
            </Box>
          )}
        </Pressable>
      ) : showNewMapUI ? (
        UpdatedSupportButton()
      ) : (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: '20px',
            borderColor: '#E8E8E8',
            borderWidth: 1,
            padding: 24,
            backgroundColor: 'white',
          }}
          flex="1"
          rounded="md"
          textAlign="center"
          maxWidth="316px"
        >
          <Image
            source={{
              uri: 'support.svg',
            }}
            resizeMode="contain"
            alt="Support"
            size="lg"
          />
          <Text fontFamily={'bodyBold'} style={{ fontSize: 14, paddingTop: 24, marginBottom: 4, flex: 1 }}>
            Need help with your delivery?
          </Text>
          <Text marginBottom={4} style={{ fontSize: 14 }}>
            Text us at 68953 to connect with a support agent.
          </Text>
          <Pressable id="contact-support" onPress={() => handleLinkClick()}>
            <Box
              paddingX="16px"
              paddingY="6px"
              bg="#ECECE5"
              rounded="lg"
              borderRadius={100}
              _text={{
                color: 'black',
                fontWeight: '600',
                fontSize: '14px',
              }}
            >
              Text Support
            </Box>
          </Pressable>
        </Box>
      )}
    </>
  )
}

export default Support
