import { Field, FieldProps } from 'formik'
import { IInputProps, TextArea } from 'native-base'

import { colors } from '../../../../utils/theme/configureTheme'

export const FormikTextArea = (props: IInputProps) => {
  const { keyboardType, name, charLimit } = props

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        return (
          <TextArea
            value={field.value}
            onChangeText={(txt: string) => form.setFieldValue(name, txt)}
            bg={colors.brand.white}
            color={colors.brand.softBlack}
            _focus={{
              bg: colors.brand.white,
              borderColor: colors.brand.softBlack,
              placeholderTextColor: colors.gray[400],
            }}
            _hover={{
              placeholderTextColor: colors.gray[400],
            }}
            size="md"
            isDisabled={props?.isDisabled || form.isSubmitting}
            alignItems="flex-start"
            keyboardType={keyboardType}
            autoCompleteType={undefined}
            borderColor={colors.brand.softBlack}
            borderWidth={2}
            h={100}
            key={`${name}-textarea-input`}
            testID={`${name}-textarea-input`}
            {...props}
            maxLength={charLimit}
            invalidOutlineColor={colors.error[300]}
          />
        )
      }}
    </Field>
  )
}
