import { IssueSubType, IssueType } from '@veho/event-types'

const events = {
  CREATED: 'created', // PICKUPS AND DELIVERIES
  CANCELLED: 'cancelled',
  PICKED_UP_FROM_CLIENT: 'pickedUpFromClient', // DELIVERIES
  DROPPED_OFF_AT_VEHO: 'droppedOffAtVeho',
  PICKED_UP_FROM_VEHO: 'pickedUpFromVeho',
  DELIVERED: 'delivered',
  DISCARDED: 'discarded',
  RETURNED: 'returned',
  RETURNED_TO_CLIENT: 'returnedToClient',
  RETURNED_TO_VEHO: 'returnedToVeho',
  PENDING: 'pending',
  MISDELIVERED: 'misdelivered',
  NOT_FOUND: 'notFound',
  NOT_RECEIVED_FROM_CLIENT: 'notReceivedFromClient',
  OUT_FOR_PICKUP: 'outForPickup', // PICKUPS
  PICKED_UP_FROM_CONSUMER: 'pickedUpFromConsumer',
  PICKUP_SKIPPED: 'pickupSkipped',
  PICKUP_FAILED: 'pickupFailed',
  PICKUP_DROPPED_OFF_AT_VEHO: 'pickupDroppedOffAtVeho',
  PACKAGE_ARRIVED_AT_FACILITY: 'PackageArrivedAtFacility',
  PACKAGE_DEPARTED_FROM_FACILITY: 'PackageDepartedFromFacility',
  PACKAGE_HAD_DELIVERY_ISSUE: 'PackageHadDeliveryIssue',
  PACKAGE_DELAYED: 'PackageDelayed',
}

export const errorEvents = [
  events.CANCELLED,
  events.DISCARDED,
  events.RETURNED,
  events.RETURNED_TO_CLIENT,
  events.RETURNED_TO_VEHO,
  events.MISDELIVERED,
  events.NOT_RECEIVED_FROM_CLIENT,
  events.PICKUP_SKIPPED,
  events.PICKUP_FAILED,
  events.PACKAGE_HAD_DELIVERY_ISSUE,
  events.PACKAGE_DELAYED,
]

export const PackageDelayedIssue = {
  accessIssueFrontGate: `${IssueType.AccessIssues} - ${IssueSubType.FrontGate}`,
  accessIssueBuildingOrDoorCode: `${IssueType.AccessIssues} - ${IssueSubType.BuildingDoorCode}`,
  accessIssueSchoolCampus: `${IssueType.AccessIssues} - ${IssueSubType.SchoolCampus}`,
  wrongLocation: `${IssueType.WrongLocation}`,
  closedLocationStoreFront: `${IssueType.ClosedLocation} - ${IssueSubType.BusinessStoreFront}`,
  closedLocationOffice: `${IssueType.ClosedLocation} - ${IssueSubType.BusinessOffice}`,
  closedLocationLeasingOffice: `${IssueType.ClosedLocation} - ${IssueSubType.LeasingOffice}`,
  closedLocationMailroom: `${IssueType.ClosedLocation} - ${IssueSubType.Mailroom}`,
  customerRefused: `${IssueType.CustomerRefused}`,
  damagedPackage: `${IssueType.DamagedPackage}`,
  unsafeConditionsPetOnSite: `${IssueType.UnsafeConditions} - ${IssueSubType.PetOnSite}`,
  unsafeConditionsWeatherRelated: `${IssueType.UnsafeConditions} - ${IssueSubType.WeatherRelated}`,
  unsafeConditionsDarkUnlitArea: `${IssueType.UnsafeConditions} - ${IssueSubType.DarkOrUnlitArea}`,
  other: `${IssueType.Other}`,
  // using a string here as PackageDelayed event is generated and injected in Esus ONLY
  packageDelayed: 'PackageDelayed',
  ranOutOfTimeRouteStartedLate: `${IssueType.RanOutOfTime} - ${IssueSubType.RouteStartedLate}`,
  ranOutOfTimeRouteTookLongerThanEstimated: `${IssueType.RanOutOfTime} - ${IssueSubType.RouteTookLongerThanEstimated}`,
  ranOutOfTimeAppIssues: `${IssueType.RanOutOfTime} - ${IssueSubType.AppIssues}`,
}

export default events
