import { ApolloLink, HttpLink, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/react'

import { getFirebaseAuthorizationHeader } from '../firebase'

// From https://stackoverflow.com/questions/47211778/cleaning-unwanted-fields-from-graphql-responses/51380645#51380645
const omitTypename = (key: string, value: unknown) => (key === '__typename' ? undefined : value)
const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename)
  }
  return forward(operation).map(data => {
    return data
  })
})

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  Sentry.withScope(scope => {
    scope.setTransactionName(operation.operationName)
    scope.setContext('apolloGraphQLOperation', {
      operationName: operation.operationName,
      variables: JSON.stringify(operation.variables),
      extensions: operation.extensions,
    })

    graphQLErrors?.forEach(error => {
      Sentry.captureMessage(error.message, {
        level: 'error',
        fingerprint: ['', ''],
        contexts: {
          apolloGraphQLError: {
            error,
            message: error.message,
            extensions: error.extensions,
          },
        },
      })
    })

    if (networkError) {
      Sentry.captureMessage(networkError.message, {
        level: 'error',
        contexts: {
          apolloNetworkError: {
            error: networkError,
          },
        },
      })
    }
  })
})

const authMiddleware = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
      serviceName: 'CWA',
      authorization: await getFirebaseAuthorizationHeader(),
    },
  }
})

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_GRAPHQL_URL,
})

export const link = from([cleanTypeName, authMiddleware, errorLink, httpLink])
