import { Alert, CloseIcon, HStack, IconButton, Text, VStack } from 'native-base'

export type ToastAlertProps = {
  id: string
  status?: 'info' | 'warning' | 'success' | 'error'
  variant?: 'solid' | 'subtle' | 'outline'
  title: string
  description: string
  isClosable: boolean
  closeToast: (id: string) => void
}

export const ToastAlert = ({
  id,
  status,
  variant,
  title,
  description,
  isClosable,
  closeToast,
  ...rest
}: ToastAlertProps) => {
  status ||= 'info'
  const textColor = variant === 'solid' ? 'lightText' : variant !== 'outline' ? `${status}.1000` : null
  const closeButtonColor = variant === 'solid' ? 'lightText' : `${status}.1000`

  return (
    <Alert maxWidth={'90svw'} alignSelf={'center'} flexDirection={'row'} status={status} variant={variant} {...rest}>
      <VStack space={1} alignItems={'stretch'} flexShrink={1} w="100%">
        <HStack flexShrink={1} alignItems={'center'} justifyContent={'space-between'}>
          <Text fontSize={'sm'} fontWeight={'medium'} lineHeight={'125%'} flexShrink={1} color={textColor}>
            {title}
          </Text>
          {isClosable ? (
            <IconButton
              variant={'unstyled'}
              icon={<CloseIcon size={3} />}
              _icon={{
                color: closeButtonColor,
              }}
              onPress={() => closeToast(id)}
              p={0}
            />
          ) : null}
        </HStack>
        <Text fontSize={'xs'} textAlign={'left'} lineHeight={'125%'} color={textColor}>
          {description}
        </Text>
      </VStack>
    </Alert>
  )
}
