import { Form, useFormikContext } from 'formik'
import { Box, HStack, Spinner, Text, VStack } from 'native-base'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import * as yup from 'yup'

import InfoCircleSVG from '../../../assets/InfoCircleSVG'
import { AddressAttribute, Order, PropertyType } from '../../../types/graphql'
import { trackEvent } from '../../../utils/analytics-v2'
import states from '../../../utils/states.json'
import { colors } from '../../../utils/theme/configureTheme'
import { QuestionGroup, SECURITY_CODE_INPUT_LABEL } from './AddressAttributesInput'
import AutofillTextInput from './Input/AutofillTextInput'
import { ControlledSelect } from './Input/ControlledSelect'
import ControlledTextInput from './Input/ControlledTextInput'
import YesNoInput from './Input/YesNoInput'
import { OtherInstructionsInput } from './OtherInstructionsInput'
import { PreferredLocationInputType } from './PreferredLocationInput'
import { PropertyTypeInputType, PropertyTypesInput, propertyTypeFieldSchema } from './PropertyTypesInput'

export interface AddressInputGroupProps {
  order: Order
  loading?: boolean
}

export interface IAddressReturnValue {
  street_line?: string
  secondary?: string
  city?: string
  state?: string
  zipcode?: string
}

export interface IAddressInputType {
  name: string
  street: string
  apartment?: string
  city: string
  state: string
  zipCode: string
}

export type AddressInputGroupType = IAddressInputType & PropertyTypeInputType & PreferredLocationInputType

const typeErrorString = 'This field must be a string.'
const typeErrorNumber = 'This field must be a number.'
const requiredErrorString = 'This field is required.'
const validUSStateString = 'Must be a valid U.S. state.'
const zipCodeLengthString = 'Must be exactly 5 or 9 characters.'
const zipCodeNotAvailable =
  "Sorry, our warehouse doesn't currently deliver to that zip code yet. If you need further assistance, please contact support"

export const addressFieldsSchema = (reroutableZipCodes?: string[]) =>
  yup.object().shape({
    name: yup.string().typeError(typeErrorString).required(requiredErrorString),
    street: yup.string().typeError(typeErrorString).required(requiredErrorString),
    apartment: yup.string().typeError(typeErrorString),
    city: yup.string().typeError(typeErrorString).required(requiredErrorString),
    state: yup
      .string()
      .typeError(typeErrorString)
      .required(requiredErrorString)
      .test('is-state', validUSStateString, value => {
        if (!states.find(s => s.value === value)) {
          return false
        }
        return true
      }),
    zipCode: yup
      .string()
      .typeError(typeErrorNumber)
      .required(requiredErrorString)
      .test('is-5-or-10', zipCodeLengthString, value => {
        if (value?.toString().length !== 5 && value?.toString().length !== 10) {
          return false
        }
        return true
      })
      .test('is-reroutable', zipCodeNotAvailable, value => {
        if (
          reroutableZipCodes &&
          reroutableZipCodes.find(zip => zip === value?.toString() || zip === value?.toString().slice(0, 5))
        ) {
          return true
        }
        return false
      }),
    type: propertyTypeFieldSchema,
  })

export const AddressForm = ({ order, loading = false }: AddressInputGroupProps) => {
  const { values, errors, setFieldValue } = useFormikContext<AddressInputGroupType>()
  const [showAccessCode, setShowAccessCode] = useState<boolean>(
    order?.address?.securityCode?.length ||
      order?.address?.apartment?.length ||
      values?.attributes?.includes(AddressAttribute.UseCodeToAccess)
      ? true
      : false
  )

  useEffect(() => {
    // set state select based on order for initial render
    setFieldValue('state', order?.address?.state)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePlaceSelect = useCallback(
    (selectedAddress: IAddressReturnValue) => {
      setFieldValue('street', selectedAddress.street_line?.trim())
      setFieldValue('apartment', selectedAddress.secondary?.trim())
      setFieldValue('city', selectedAddress.city?.trim())
      setFieldValue('state', selectedAddress.state?.trim())
      setFieldValue('zipCode', selectedAddress.zipcode?.trim())
    },
    [setFieldValue]
  )

  const handleStateSelectChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setFieldValue('state', e.target.value)
    },
    [setFieldValue]
  )

  const handleContactSupport = () => {
    trackEvent('contact_support', { method: 'sms', features: 'cwa_map_view' })
    window.location.href = `sms:68953`
  }

  const handleYesNoInput = () => {
    const prefSet = new Set<string>(values?.attributes)
    if (!showAccessCode) {
      prefSet.add(QuestionGroup.UseCodeToAccess)
    } else {
      prefSet.delete(QuestionGroup.UseCodeToAccess)
      setFieldValue('securityCode', '')
    }

    setShowAccessCode(!showAccessCode)
    const attrs = Array.from(prefSet)
    setFieldValue('attributes', attrs)
  }

  const stateAndZipCodeErrorText = ({ state, zipCode }: { state?: string; zipCode?: string }) => {
    // starts as incomplete string - remainder of "contact support" needs to be clickable/tappable string to open SMS
    const invalidZipContactSupportPrefix =
      "Sorry, our warehouse doesn't currently deliver to that zip code yet. If you need further assistance, please "
    const invalidZipError = zipCode?.includes(zipCodeNotAvailable)
    let errorString: string
    if (state && zipCode) {
      errorString = 'Both fields are required'
    } else if (state) {
      errorString = 'State field is required'
    } else if (zipCode) {
      errorString = invalidZipError ? invalidZipContactSupportPrefix : 'Zip code is required'
    } else {
      errorString = 'Field is required'
    }

    return invalidZipError ? (
      <Text color={colors.error[500]} fontSize={14} fontFamily={'body'}>
        {errorString}
        <Text onPress={handleContactSupport} underline bold>
          contact support
        </Text>
      </Text>
    ) : (
      <Text color={colors.error[500]} fontSize={14} fontFamily={'body'}>
        {errorString}
      </Text>
    )
  }

  return loading ? (
    <VStack space={4} alignItems={'center'} p={3} my={4}>
      <Spinner size={'lg'} color={'primary.300'} outlineStyle={'none'} />
    </VStack>
  ) : (
    <Form style={{ width: '100%' }}>
      <ControlledTextInput
        isDisabled={true}
        label={"Recipient's Full Name"}
        name="name"
        value={order?.address?.name}
        style={{
          width: '100%',
        }}
      />
      <PropertyTypesInput serviceType={order.serviceType} width={'100%'} />
      <Box
        flex={1}
        alignItems={'flex-start'}
        width={'110%'}
        backgroundColor={showAccessCode ? colors.brand.lightGrey : 'transparent'}
        mt={1}
        mb={showAccessCode ? 1 : 0}
        ml={-3}
        px={3}
        py={showAccessCode ? 2 : 0}
        borderRadius={8}
      >
        <Box mb={showAccessCode ? 1 : 0}>
          <Text fontSize={13} color={colors.brand.softBlack} fontWeight={'600'}>
            Security Code or Callbox to access?
          </Text>
          <YesNoInput handleChange={handleYesNoInput} name={'securityCode'} value={showAccessCode} />
        </Box>
        {showAccessCode && (
          <ControlledTextInput
            hint={'Include additional characters such as # or *'}
            leftHintIcon={<InfoCircleSVG />}
            placeholder={'e.g. 123456#'}
            label={SECURITY_CODE_INPUT_LABEL[values.type]}
            name="securityCode"
            value={values.securityCode}
            charLimit={20}
            charLimitCounter={false}
            containerBg={'transparent'}
            hintContainerBg={'transparent'}
            borderColor={colors.brand.darkGrey}
            _focus={{ borderColor: colors.brand.softBlack }}
          />
        )}
      </Box>
      <AutofillTextInput
        onPlaceSelect={handlePlaceSelect}
        handleBlur={setFieldValue}
        label={'Street'}
        name="street"
        clearable={true}
      />
      {values.type !== PropertyType.House && (
        <ControlledTextInput
          label={values.type === PropertyType.Business ? 'Floor/Suite Number' : 'Apartment/Unit Number'}
          optional={true}
          name="apartment"
          value={values.apartment}
          autocomplete={'address-line2'}
        />
      )}
      <ControlledTextInput
        label={'City'}
        name="city"
        value={values.city}
        style={{ width: '100%' }}
        borderColor={colors.brand.darkGrey}
        _focus={{ borderColor: colors.brand.softBlack }}
        autocomplete={'address-level2'}
      />
      <VStack width={'100%'}>
        <HStack direction="row" alignItems={'flex-end'}>
          <ControlledSelect
            label={'State'}
            name="state"
            width={'65%'}
            onChange={handleStateSelectChange}
            autocomplete={'address-level1'}
          >
            {states.map(state => {
              return (
                <option key={`state-${state.value}`} value={state.value}>
                  {state.label}
                </option>
              )
            })}
          </ControlledSelect>
          <ControlledTextInput
            hideErrorMessage={true}
            keyboardType="number-pad"
            label={'Zipcode'}
            name="zipCode"
            width={'48%'}
            borderColor={colors.brand.darkGrey}
            _focus={{ borderColor: colors.brand.softBlack }}
            style={{ marginLeft: 16, marginBottom: -1 }}
            leftIcon={<Box mr={'-16px'}></Box>}
            overflow={'hidden'}
            autocomplete={'postal-code'}
          />
        </HStack>
        {(errors?.zipCode || errors?.state) && (
          <Box>{stateAndZipCodeErrorText({ state: errors?.state, zipCode: errors?.zipCode })}</Box>
        )}
      </VStack>
      <Box flexDirection={'row'} alignItems={'flex-start'} justifyContent={'flex-start'} width={'100%'} mt={2}>
        <OtherInstructionsInput serviceType={order.serviceType} />
      </Box>
    </Form>
  )
}
