import { Box, Text } from 'native-base'

import { Warning } from '@phosphor-icons/react'

export interface AlertProps {}

export const Alert: React.FC<AlertProps> = () => (
  <Box
    backgroundColor={'#ECECE5'}
    borderWidth={1}
    borderRadius={8}
    borderColor={'#C3C3BE'}
    padding={2}
    marginTop={4}
    display="flex"
    flexDirection="row"
  >
    <Box width={8} height={10} paddingTop={2.5} paddingLeft={2}>
      <Warning size={24} color="#21211f" weight="bold" />
    </Box>
    <Box padding={2} flex="min-content">
      <Text fontSize={18} bold>
        Delays in your area
      </Text>
      <Text fontSize={12}>
        We are experiencing widespread delays in your area that may impact your expected delivery date or time.
      </Text>
    </Box>
  </Box>
)
