import { Box, Image, Pressable, Text, VStack } from 'native-base'
import { isMobile } from 'react-device-detect'

import { useGateValue } from '@statsig/react-bindings'

import { trackEvent } from '../utils/analytics-v2'
import { STATSIG_FEATURE_GATES } from '../utils/enums'

const CustomerFeedback = ({
  showMobile,
  maxWidth,
  maxHeight,
  height,
}: {
  showMobile: boolean
  maxWidth?: string
  maxHeight?: string
  height?: string
}) => {
  const showNewMapUI = useGateValue(STATSIG_FEATURE_GATES.showNewMapUI)
  const handleLinkClick = (href: string) => {
    trackEvent('provide_feedback', { method: 'google_form' })
    window.open(href, '_blank', 'noreferrer')
  }

  const UpdatedFeedbackButton = () => {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: '20px',
          borderColor: '#E8E8E8',
          borderWidth: 1,
          padding: 24,
          backgroundColor: 'white',
        }}
        borderRadius={'24px'}
        maxWidth={maxWidth}
        maxH={maxHeight}
        height={height}
      >
        <Image
          source={{
            uri: 'feedback.svg',
          }}
          resizeMode="contain"
          alt="Feedback"
          size="md"
        />
        <VStack display={'flex'} alignItems={'flex-start'} flex={1}>
          <Text fontWeight="700" fontFamily={'bodyBold'} style={{ fontSize: 14, marginBottom: 4 }}>
            Have thoughts?
          </Text>
          <Text marginBottom={2.5} style={{ fontSize: 14 }}>
            Let us know how we can make your delivery experience even better.
          </Text>
          <Pressable
            id="provideCustomerFeeback"
            onPress={() =>
              handleLinkClick('https://docs.google.com/forms/d/14O3l7sCsZEyYmj3mJs85JjJRsWI6A3RQoro2AKBA1KQ/edit')
            }
          >
            <Box
              bg="black"
              rounded="lg"
              paddingX="16px"
              paddingY="6px"
              borderRadius={100}
              _text={{
                color: 'white',
                fontWeight: '600',
                fontSize: '14px',
              }}
            >
              Give Feedback
            </Box>
          </Pressable>
        </VStack>
      </Box>
    )
  }

  return (
    <>
      {isMobile || showMobile ? (
        <Pressable
          id="provideCustomerFeeback"
          onPress={() =>
            handleLinkClick('https://docs.google.com/forms/d/14O3l7sCsZEyYmj3mJs85JjJRsWI6A3RQoro2AKBA1KQ/edit')
          }
        >
          {showNewMapUI ? (
            UpdatedFeedbackButton()
          ) : (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                paddingTop: '20px',
                paddingBottom: '20px',
                borderColor: '#E8E8E8',
                borderWidth: 1,
                paddingLeft: '16px',
                backgroundColor: 'white',
              }}
              flex="1"
              rounded="md"
              textAlign="left"
              height="98px"
              marginX="16px"
            >
              <Box paddingRight="16px">
                <Image
                  source={{
                    uri: 'feedback.svg',
                  }}
                  resizeMode="contain"
                  alt="Feedback"
                  size="sm"
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-start"
                flex="1"
                paddingRight="20px"
              >
                <Text fontFamily={'bodyBold'} style={{ fontSize: 14, marginBottom: 4 }}>
                  Have feedback on this site?
                </Text>
                <Text style={{ fontSize: 14 }}>Tell us how we can make your tracking experience better.</Text>
              </Box>
              <Box marginRight="26px" alignItems="center" justifyContent="center">
                <img src="/chevron_right.svg" alt="logo" width={10} height={16} />
              </Box>
            </Box>
          )}
        </Pressable>
      ) : showNewMapUI ? (
        <UpdatedFeedbackButton />
      ) : (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '20px',
            borderColor: '#E8E8E8',
            borderWidth: 1,
            padding: 24,
            backgroundColor: 'white',
          }}
          flex="1"
          rounded="md"
          textAlign="center"
          maxWidth="316px"
        >
          <Image
            source={{
              uri: 'feedback.svg',
            }}
            resizeMode="contain"
            alt="Feedback"
            size="lg"
          />
          <Text
            fontWeight="700"
            fontFamily={'bodyBold'}
            justifyContent="center"
            style={{ fontSize: 14, paddingTop: 24, marginBottom: 4, flex: 1 }}
          >
            Have feedback on this site?
          </Text>
          <Text marginBottom={4} style={{ fontSize: 14 }}>
            Tell us how we can make your tracking experience better.
          </Text>
          <Pressable
            id="provideCustomerFeeback"
            onPress={() =>
              handleLinkClick('https://docs.google.com/forms/d/14O3l7sCsZEyYmj3mJs85JjJRsWI6A3RQoro2AKBA1KQ/edit')
            }
          >
            <Box
              bg="#ECECE5"
              rounded="lg"
              paddingX="16px"
              paddingY="6px"
              borderRadius={100}
              _text={{
                color: 'black',
                fontWeight: '600',
                fontSize: '14px',
              }}
            >
              Give Feedback
            </Box>
          </Pressable>
        </Box>
      )}
    </>
  )
}

export default CustomerFeedback
