import Svg, { Path } from 'react-native-svg'

const InfoCircleSVG = () => (
  <Svg
    style={{ position: 'absolute', left: 0 }}
    width={16}
    height={16}
    fill="none"
    // viewBox="0 -5 16 20"
  >
    <Path
      fill="#858581"
      d="M6.75 5.25a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm8 2.75A6.75 6.75 0 1 1 8 1.25 6.758 6.758 0 0 1 14.75 8Zm-1.5 0a5.25 5.25 0 1 0-10.5 0 5.25 5.25 0 0 0 10.5 0Zm-4.5 2.293V8.25A1.25 1.25 0 0 0 7.5 7a.75.75 0 0 0-.25 1.457V10.5a1.25 1.25 0 0 0 1.25 1.25.75.75 0 0 0 .25-1.457Z"
    />
  </Svg>
)
export default InfoCircleSVG
