import { useFormikContext } from 'formik'
import { Heading, VStack } from 'native-base'
import * as yup from 'yup'

import PhoneFormVerifySVG from '../../../assets/PhoneFormVerifySVG'
import { ControlledCodeInput } from '../Form/Input/ControlledCodeInput'
import { ResendCode } from './ResendCode'

const verificationCodeLength = 'Must be exactly 6 characters.'
const typeErrorNumber = 'This field must be a number.'
const requiredField = 'This field is required.'

export const verificationFormSchema = yup.object().shape({
  confirmationCode: yup
    .string()
    .length(6, verificationCodeLength)
    .matches(/[0-9]{6}/, typeErrorNumber)
    .required(requiredField),
})

export type VerificationFormType = {
  confirmationCode: string
}

export type VerificationInputProps = {
  resendAuth: () => void
  resendLoading: boolean
  disableSubmit: boolean
  disableResend: boolean
}

export const VerificationInput = ({
  resendAuth,
  resendLoading,
  disableSubmit,
  disableResend,
}: VerificationInputProps) => {
  const { handleChange, values, errors, setFieldValue, handleSubmit } = useFormikContext<VerificationFormType>()

  return (
    <VStack alignItems={'stretch'} space={4}>
      <VStack alignItems={'center'}>
        <PhoneFormVerifySVG />
      </VStack>
      <Heading size={'sm'} textAlign={'center'}>
        Enter the 6 digit code we texted you.
      </Heading>
      <ControlledCodeInput
        cellCount={6}
        setFieldValue={setFieldValue}
        handleChange={handleChange}
        submitForm={handleSubmit}
        name="confirmationCode"
        error={errors.confirmationCode}
        value={values.confirmationCode}
        editable={!disableSubmit}
      />
      <ResendCode
        resendAuth={resendAuth}
        resendLoading={resendLoading}
        disableSubmit={disableSubmit}
        disableResend={disableResend}
      />
    </VStack>
  )
}
