import { gql } from 'graphql-tag'

export const UPDATE_CLIENT_ADDRESS_INSTRUCTIONS_FOR_ORDER = gql`
  mutation updateClientAddressInstructionsForOrder($orderId: ID!, $updateInstructionsInput: UpdateInstructionsInput!) {
    updateClientAddressInstructionsForOrder(orderId: $orderId, updateInstructionsInput: $updateInstructionsInput) {
      instructions
      preferredLocation
      pickupInstructions
      preferredPickupLocation
      securityCode
      attributes
    }
  }
`
