import { Button, Heading, Modal, Text, VStack } from 'native-base'
import { useContext } from 'react'

import WarningTriangleSVG from '../../../assets/WarningTriangleSVG'
import { OverlayState } from '../../../context/OverlayContextProvider'
import { AddInstructionsLaterButton } from '../Common/AddInstructionsLaterButton'

export type PhoneNumberMismatchModalContentProps = {
  onSubmit: () => void
  closeModal: () => void
  modalHeader?: string
  cancelText?: string
}

export const PhoneNumberMismatchModalContent = ({
  onSubmit,
  closeModal,
  modalHeader = 'Issue Submitting Instructions',
  cancelText,
}: PhoneNumberMismatchModalContentProps) => {
  const { authFlow } = useContext(OverlayState)

  return (
    <Modal.Content
      justifyContent={'center'}
      alignItems={'stretch'}
      overflowY={'auto'}
      bg={'brand.warmWhite'}
      width={350}
      maxWidth={'90svw'}
      height={550}
      maxHeight={'95svh'}
    >
      <VStack space={4} p={3} my={4}>
        <Heading textAlign={'center'} size={'md'} fontWeight={500}>
          {authFlow === 'general' || 'edit_address' ? null : modalHeader}
        </Heading>
        <VStack space={1} alignItems={'center'} w={'100%'}>
          <WarningTriangleSVG />
          <Heading textAlign={'center'} size={'sm'} fontWeight={500}>
            Your phone number doesn't match
          </Heading>
          <Text textAlign={'center'} fontSize={14} color={'brand.softBlack'}>
            The phone number you entered does not match the phone number on file for this order.
          </Text>
        </VStack>
        <VStack space={1} alignItems={'center'} w={'100%'}>
          <Button onPress={onSubmit} _focusVisible={{ outlineStyle: 'none' }}>
            Enter New Phone Number
          </Button>
          <AddInstructionsLaterButton
            closeModal={closeModal}
            cancelText={authFlow === 'general' || 'edit_address' ? 'Cancel' : cancelText}
          />
        </VStack>
      </VStack>
    </Modal.Content>
  )
}
