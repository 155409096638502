import { Box, HStack, Pressable, Text, VStack } from 'native-base'
import { useCallback } from 'react'
import { isMobile } from 'react-device-detect'

import phoneImage from '../assets/banner-phone.png'
import { trackEvent } from '../utils/analytics-v2'
import { useScreenSize } from '../utils/displayUtils'
import { colors } from '../utils/theme/configureTheme'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    MSStream: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    opera: any
  }
}

export const CMABanner = () => {
  const screenSize = useScreenSize()
  const isMobileScreen = isMobile || screenSize.width < 1000

  const handleLinkClick = useCallback((href: string, type: 'ios' | 'android') => {
    trackEvent('download_app', { element: 'banner_ad_1', platform: type })
    window.open(href, '_self')
  }, [])

  const handleBannerClick = useCallback(() => {
    const userAgent = navigator?.userAgent || navigator?.vendor || window.opera
    const isAndroid = /android/i.test(userAgent)
    const isIOS = /iPad|iPhone/.test(userAgent) && !window.MSStream

    if (isMobileScreen && (isAndroid || isIOS)) {
      let appStoreUrl: string

      if (isAndroid) {
        appStoreUrl = 'https://play.google.com/store/apps/details?id=com.veho.consumermobile&hl=en_US&gl=US&pli=1'
        return handleLinkClick(appStoreUrl, 'android')
      } else {
        // default to iOS AppStore link if unable to determine platform
        appStoreUrl = 'https://apps.apple.com/us/app/veho-mobile/id1618482681'
        return handleLinkClick(appStoreUrl, 'ios')
      }
    } else {
      return null
    }
  }, [handleLinkClick, isMobileScreen])

  return (
    <Pressable
      display="flex"
      width={'100%'}
      maxHeight={isMobileScreen ? '109px' : '168px'}
      onPress={() => handleBannerClick()}
    >
      <HStack alignItems={'center'} justifyContent={'space-between'} backgroundColor={'#DCEBF7'}>
        <VStack pl={isMobileScreen ? '16px' : '32px'} width={'65%'} alignItems={'flex-start'} justifyContent={'center'}>
          <Box mb={isMobileScreen ? 0 : 3}>
            <Text
              fontFamily={'bodyBold'}
              style={{ fontSize: isMobileScreen ? 14 : 22 }}
              fontWeight={700}
              lineHeight={isMobileScreen ? 17 : 27}
              color={colors.brand.vermillion}
              mb={'4px'}
            >
              Download the Veho app!
            </Text>
            <Text
              fontFamily={'bodyBold'}
              style={{ fontSize: isMobileScreen ? 22 : screenSize.width < 1100 ? 30 : 36 }}
              fontWeight={700}
              lineHeight={isMobileScreen ? 20 : 36}
              color={colors.brand.softBlack}
            >
              Turn delivery from hassle to convenience.
            </Text>
          </Box>
          {!isMobileScreen && (
            <HStack>
              <Pressable
                id="downloadIOS"
                onPress={() => handleLinkClick('https://apps.apple.com/us/app/veho-mobile/id1618482681', 'ios')}
                mr={2}
              >
                <img src="/app-store.svg" alt="" />
              </Pressable>
              <Pressable
                id="downloadAndroid"
                onPress={() =>
                  handleLinkClick(
                    'https://play.google.com/store/apps/details?id=com.veho.consumermobile&hl=en_US&gl=US&pli=1',
                    'android'
                  )
                }
              >
                <img src="/play-store.svg" alt="" />
              </Pressable>
            </HStack>
          )}
        </VStack>
        <div
          style={{
            backgroundImage: `linear-gradient(90deg, rgba(220,235,247,1) 0%, rgba(0,0,0,0) 50%), url(${phoneImage})`,
            backgroundPosition: '45%',
            backgroundSize: 'cover',
            height: isMobileScreen ? '109px' : '168px',
            width: isMobileScreen ? '231px' : '560px',
          }}
        ></div>
      </HStack>
    </Pressable>
  )
}
