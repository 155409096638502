import { Button, Heading, Modal, Text, VStack } from 'native-base'

import { AddInstructionsLaterButton } from './AddInstructionsLaterButton'

export type ModalContentProps = {
  headerMain: string
  headerSecondary: string
  submitText: string
  onSubmit: () => void
  closeModal: () => void
  disabled: boolean
  loading: boolean
  children?: React.ReactNode
  cancelText?: string
  submitButtonStyles?: object
}

export const ModalContent = ({
  headerMain,
  headerSecondary,
  submitText,
  onSubmit,
  closeModal,
  disabled,
  loading,
  children,
  cancelText,
  submitButtonStyles,
}: ModalContentProps) => {
  return (
    <Modal.Content
      alignItems={'stretch'}
      overflowY={'auto'}
      bg={'brand.warmWhite'}
      width={350}
      maxWidth={'90svw'}
      maxHeight={'95svh'}
    >
      <VStack space={4} alignItems={'center'} p={3} my={3}>
        <VStack space={1} alignItems={'center'} w={'100%'}>
          <Heading textAlign={'center'}>{headerMain}</Heading>
          <Text textAlign={'center'}>{headerSecondary}</Text>
        </VStack>
        {children}
        <VStack space={1} alignItems={'center'} w={'100%'}>
          <Button onPress={onSubmit} isDisabled={disabled} isLoading={loading} style={{ ...submitButtonStyles }}>
            {submitText}
          </Button>
          <AddInstructionsLaterButton closeModal={closeModal} cancelText={cancelText} />
        </VStack>
      </VStack>
    </Modal.Content>
  )
}
