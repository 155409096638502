import { Amplify } from 'aws-amplify'
import { NativeBaseProvider } from 'native-base'
import { createRoot } from 'react-dom/client'
import { HashRouter } from 'react-router-dom'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import App from './App'
import awsConfig from './awsConfig'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { theme } from './utils/theme/configureTheme'

Sentry.init({
  dsn: 'https://3a279920e8be429e882f9585608eca6d@o174236.ingest.sentry.io/6619792',
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_STAGE,
  tracesSampleRate: 0,
})

Amplify.configure(awsConfig)

const container = document.getElementById('app')
const root = createRoot(container!)

const loadApp = () => {
  root.render(
    <NativeBaseProvider theme={theme}>
      <HashRouter>
        <App />
      </HashRouter>
    </NativeBaseProvider>
  )
}

loadApp()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
