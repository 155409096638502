/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, FormControl, Text } from 'native-base'
import React, { useCallback } from 'react'
import { CodeField, Cursor, useBlurOnFulfill, useClearByFocusCell } from 'react-native-confirmation-code-field'

import { colors } from '../../../../utils/theme/configureTheme'

export interface IControlledCodeInputProps {
  cellCount: number
  error?: string
  handleChange: any
  label?: string
  name: string
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  submitForm: any
  value: string
  editable?: boolean
}

export const ControlledCodeInput = ({
  cellCount,
  error,
  handleChange,
  label,
  name,
  setFieldValue,
  submitForm,
  value,
  editable = true,
}: IControlledCodeInputProps) => {
  const confirmationCodeRef = useBlurOnFulfill({ value, cellCount })
  const [confirmationCodeProps, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue: handleChange(name),
  })

  const onChangeText = useCallback(
    (e: string) => {
      setFieldValue(name, e)

      // Auto-submit the form once it's filled out
      if (e.length === 6) {
        setTimeout(submitForm, 0)
      }
    },
    [name, setFieldValue, submitForm]
  )

  return (
    <FormControl isInvalid={error ? true : false} testID={`${name}-form-control`} bg="transparent">
      {label && <FormControl.Label testID={`${name}-label`}>{label}</FormControl.Label>}
      <CodeField
        ref={confirmationCodeRef}
        onChangeText={onChangeText}
        cellCount={cellCount}
        keyboardType="number-pad"
        textContentType="oneTimeCode"
        renderCell={({ index, symbol, isFocused }) => (
          <Box bg={'brand.white'} borderRadius={4} key={`box-${index}`}>
            <Text
              key={index}
              style={[
                {
                  width: 40,
                  height: 40,
                  lineHeight: 34,
                  fontSize: 20,
                  borderWidth: 2,
                  borderRadius: 4,
                  borderColor: colors.brand.softBlack,
                  color: colors.brand.softBlack,
                  textAlign: 'center',
                },
                isFocused && {
                  borderColor: colors.gray[500],
                },
              ]}
              onLayout={getCellOnLayoutHandler(index)}
            >
              {symbol || (isFocused ? <Cursor /> : null)}
            </Text>
          </Box>
        )}
        testID={`${name}-input`}
        value={value}
        editable={editable}
        {...confirmationCodeProps}
      />
      {error && (
        <FormControl.ErrorMessage fontSize={12} testID={`${name}-error`}>
          {error}
        </FormControl.ErrorMessage>
      )}
    </FormControl>
  )
}

ControlledCodeInput.defaultProps = {
  required: false,
  type: 'text',
}
