const REDIRECT_DOMAINS: Record<string, string> = {
  local: 'http://localhost:3000',
  dev: 'https://track2.app.dev.shipveho.com',
  staging: 'https://track2.app.staging.shipveho.com',
  prod: 'https://track2.app.shipveho.com',
  sandbox: 'https://track-sandbox.shipveho.com',
}

const awsConfig = {
  // Auth config
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_COGNITO_USER_POOL_DOMAIN,
    scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: REDIRECT_DOMAINS[process.env.REACT_APP_STAGE ?? 'local'],
    redirectSignOut: REDIRECT_DOMAINS[process.env.REACT_APP_STAGE ?? 'local'],
    clientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
    responseType: 'code',
    label: 'Sign In with Veho',
  },
}

export const oauth = awsConfig?.oauth

export default awsConfig
