import { Field, FieldProps } from 'formik'
import { IInputProps, Input } from 'native-base'

import { colors } from '../../../../utils/theme/configureTheme'

export const FormikInput = (props: IInputProps) => {
  const { autoComplete, autoFocus, keyboardType, leftIcon, name, charLimit } = props

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        return (
          <Input
            value={field.value}
            onChangeText={(txt: string) => form.setFieldValue(name, txt)}
            key={`${name}-text-input`}
            testID={`${name}-text-input`}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            bg={colors.brand.white}
            color={colors.brand.softBlack}
            _focus={{
              bg: colors.brand.white,
              borderColor: colors.brand.softBlack,
              placeholderTextColor: colors.gray[400],
            }}
            _hover={{
              placeholderTextColor: colors.gray[400],
            }}
            size="md"
            isDisabled={props?.isDisabled || form.isSubmitting}
            InputLeftElement={leftIcon}
            keyboardType={keyboardType}
            borderColor={colors.brand.softBlack}
            borderWidth={2}
            maxLength={charLimit}
            {...props}
          />
        )
      }}
    </Field>
  )
}
