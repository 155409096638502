import Svg, { Path } from 'react-native-svg'

const PencilSimpleSVG = () => {
  return (
    <Svg width={16} height={16} fill="none" viewBox="0 0 16 16">
      <Path
        d="M14.3838 4.40893L11.5913 1.61581C11.4752 1.49968 11.3374 1.40756 11.1857 1.34471C11.034 1.28186 10.8714 1.24951 10.7072 1.24951C10.543 1.24951 10.3804 1.28186 10.2287 1.34471C10.077 1.40756 9.93921 1.49968 9.82313 1.61581L2.11626 9.32331C1.99976 9.43904 1.90739 9.57676 1.84452 9.72846C1.78165 9.88017 1.74953 10.0428 1.75001 10.2071V13.0002C1.75001 13.3317 1.8817 13.6496 2.11612 13.8841C2.35054 14.1185 2.66848 14.2502 3.00001 14.2502H5.79313C5.95734 14.2506 6.12001 14.2185 6.27171 14.1556C6.42341 14.0927 6.56112 14.0004 6.67688 13.8839L14.3838 6.17643C14.6181 5.94202 14.7497 5.62414 14.7497 5.29268C14.7497 4.96123 14.6181 4.64334 14.3838 4.40893ZM5.68751 12.7502H3.25001V10.3127L8.50001 5.06268L10.9375 7.50018L5.68751 12.7502ZM12 6.43768L9.56251 4.00018L10.7088 2.85393L13.1463 5.29143L12 6.43768Z"
        fill="#21211F"
      />
    </Svg>
  )
}

export default PencilSimpleSVG
