import { Box, HStack, Heading, Text } from 'native-base'
import { useContext } from 'react'

import { Package as PackageIcon } from '@phosphor-icons/react'

import { AppContext } from '../context/AppContextProvider'

const OrderInfoHeader = ({ trackingId }: { trackingId: string }) => {
  const { order } = useContext(AppContext)

  if (!order) {
    return null
  }

  return (
    <Box width="100%" paddingBottom="24px">
      <HStack
        borderBottomColor={'#C3C3BE'}
        borderBottomWidth={1}
        paddingBottom={2}
        paddingLeft={2}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <HStack alignItems={'center'}>
          <Box backgroundColor={'#ECECE5'} p={2} height={9} mr={2} borderRadius={8}>
            <PackageIcon size={24} color="#21211F" weight="bold" />
          </Box>
          <Box>
            <Heading
              style={{
                fontSize: 16,
              }}
              fontFamily={'bodyBold'}
              fontWeight="700"
            >
              {order.clientDisplayName || order.clientName}
            </Heading>
            <Text
              style={{
                fontSize: 12,
              }}
              color={'#858581'}
            >
              Tracking ID {trackingId}
            </Text>
          </Box>
        </HStack>
      </HStack>
    </Box>
  )
}

export default OrderInfoHeader
