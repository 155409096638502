import { Button, HStack, Text } from 'native-base'

export type ResendCodeProps = {
  resendAuth: () => void
  resendLoading: boolean
  disableSubmit: boolean
  disableResend: boolean
}

export const ResendCode = ({ resendAuth, resendLoading, disableSubmit, disableResend }: ResendCodeProps) => {
  return (
    <HStack justifyContent={'center'} alignItems={'center'}>
      <Text fontSize={14} color={'gray.500'} textAlign={'center'}>
        Didn't receive a verification code?
      </Text>
      <Button
        onPress={resendAuth}
        isDisabled={disableResend || disableSubmit}
        isLoading={resendLoading}
        variant={'link'}
        ml={-2}
        py={0}
      >
        <Text fontSize={14} color={'gray.600'} fontWeight={'bold'} textDecorationLine={'underline'}>
          Resend
        </Text>
      </Button>
    </HStack>
  )
}
