import { FormControl, HStack, Pressable, StyledProps, Text, VStack } from 'native-base'
import React, { memo } from 'react'
import { ViewProps } from 'react-native'

import { colors } from '../../../../utils/theme/configureTheme'

interface YesNoInputProps extends ViewProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any
  handleChange: (newValue: boolean) => void
  label?: string
  name: string
  value: boolean
  disabled?: boolean
}

const YesNoInput = ({ error, handleChange, label, name, value, disabled = false, ...viewProps }: YesNoInputProps) => {
  // Pressable does not support variants, so we must style it manually
  const basePressableStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 4,
    borderWidth: 2,
    opacity: disabled ? 0.75 : 1,
  }

  const opacityStyle = {
    opacity: disabled ? 0.75 : 1,
  }

  const unselectedStyle = {
    color: colors.brand.softBlack,
    borderColor: colors.brand.softBlack,
    backgroundColor: colors.brand.white,
  }
  const selectedStyle = {
    color: colors.brand.softBlack,
    borderColor: colors.brand.softBlack,
    backgroundColor: colors.brand.selectedBlue,
  }

  const focusSelectedStyle: StyledProps = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    borderWidth: 2,
    paddingX: 3,
    paddingY: 2,
    opacity: disabled ? 0.75 : 1,
    outlineStyle: 'none',
    shadow: 'none',
    borderColor: 'brand.softBlack',
    backgroundColor: 'brand.selectedBlue',
  }
  const focusUnselectedStyle: StyledProps = {
    ...focusSelectedStyle,
    backgroundColor: 'brand.white',
  }

  return (
    <VStack space={1} {...viewProps}>
      {label && (
        <Text
          key={`${name}-label`}
          color={'brand.softBlack'}
          fontSize={13}
          fontWeight={'600'}
          lineHeight={'150%'}
          testID={`${name}-label`}
        >
          {label}
        </Text>
      )}
      <HStack space={2}>
        <Pressable
          key={`${name}-yes`}
          disabled={disabled}
          onPress={() => {
            handleChange(true)
          }}
          style={[basePressableStyle, opacityStyle, value ? selectedStyle : unselectedStyle]}
          _focusVisible={value ? focusSelectedStyle : focusUnselectedStyle}
          accessible
          accessibilityLabel={`${label} Yes`}
        >
          <Text variant={'pressable'}>Yes</Text>
        </Pressable>
        <Pressable
          key={`${name}-no`}
          disabled={disabled}
          onPress={() => {
            handleChange(false)
          }}
          style={[basePressableStyle, opacityStyle, !value ? selectedStyle : unselectedStyle]}
          _focusVisible={!value ? focusSelectedStyle : focusUnselectedStyle}
          accessible
          accessibilityLabel={`${label} No`}
        >
          <Text variant={'pressable'}>No</Text>
        </Pressable>
      </HStack>
      {error && (
        <FormControl.ErrorMessage key={`${name}-error`} fontSize={12} testID={`${name}-error`}>
          {error}
        </FormControl.ErrorMessage>
      )}
    </VStack>
  )
}

export default memo(YesNoInput)
