import { BREADCRUMB_TYPES } from './enums'
import { logSentryBreadcrumb, logSentryError } from './sentry'

// TO DO: Add Clarity React for improved monitoring/identification of users
// TO DO: Add New Relic for improved logging and visibiltiy

export function logError(message: string, context?: object) {
  logSentryError(message, context)
}

export function logBreadcrumb(message: string, type: BREADCRUMB_TYPES = BREADCRUMB_TYPES.manual, data = {}) {
  logSentryBreadcrumb(message, type, data)
}

export interface ConsumerNoPii {
  consumerId: string
  agreedToTermsAndConditions?: boolean | null
  latestTermsVersionAgreedTo?: string | null
  submittedAppReviewDate: string | null
  firebaseId?: string
}

export async function identifyMetricUser(user: ConsumerNoPii) {
  // TO DO: add identify functions for Sentry, New Relic, and Clarify
}
