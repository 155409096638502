import { useFormikContext } from 'formik'
import { useEffect } from 'react'

import { trackEvent } from '../../../utils/analytics-v2'
import { ModalContent } from '../Common/ModalContent'
import { PhoneFormType, PhoneInput } from './PhoneInput'

export type PhoneModalContentProps = {
  closeModal: () => void
  modalHeaderText?: string
  modalBodyText?: string
  cancelText?: string
  termsAndConditionsAction?: string
}

export const PhoneModalContent = ({
  closeModal,
  modalHeaderText = 'Confirm Instructions',
  modalBodyText = 'We need to verify your phone number to add delivery instructions.',
  cancelText,
  termsAndConditionsAction,
}: PhoneModalContentProps) => {
  const { isSubmitting, values, handleSubmit } = useFormikContext<PhoneFormType>()

  useEffect(() => {
    trackEvent('view_authentication', { verification_type: 'phone' })
  }, [])
  return (
    <ModalContent
      headerMain={modalHeaderText}
      headerSecondary={modalBodyText}
      submitText={'Get Code'}
      onSubmit={handleSubmit}
      closeModal={closeModal}
      disabled={isSubmitting || !values.acceptedTerms}
      loading={isSubmitting}
      cancelText={cancelText}
      submitButtonStyles={{ width: '60%' }}
    >
      <PhoneInput termsAndConditionsAction={termsAndConditionsAction} />
    </ModalContent>
  )
}
