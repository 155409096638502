import Svg, { Path } from 'react-native-svg'

const PlusSVG = () => {
  return (
    <Svg width={17} height={17} fill="none" viewBox="0 0 17 17">
      <Path
        d="M14.6484 8.08789C14.6484 8.2868 14.5694 8.47757 14.4288 8.61822C14.2881 8.75887 14.0974 8.83789 13.8984 8.83789H9.14844V13.5879C9.14844 13.7868 9.06942 13.9776 8.92877 14.1182C8.78812 14.2589 8.59735 14.3379 8.39844 14.3379C8.19953 14.3379 8.00876 14.2589 7.86811 14.1182C7.72746 13.9776 7.64844 13.7868 7.64844 13.5879V8.83789H2.89844C2.69953 8.83789 2.50876 8.75887 2.36811 8.61822C2.22746 8.47757 2.14844 8.2868 2.14844 8.08789C2.14844 7.88898 2.22746 7.69821 2.36811 7.55756C2.50876 7.41691 2.69953 7.33789 2.89844 7.33789H7.64844V2.58789C7.64844 2.38898 7.72746 2.19821 7.86811 2.05756C8.00876 1.91691 8.19953 1.83789 8.39844 1.83789C8.59735 1.83789 8.78812 1.91691 8.92877 2.05756C9.06942 2.19821 9.14844 2.38898 9.14844 2.58789V7.33789H13.8984C14.0974 7.33789 14.2881 7.41691 14.4288 7.55756C14.5694 7.69821 14.6484 7.88898 14.6484 8.08789Z"
        fill="#21211F"
      />
    </Svg>
  )
}

export default PlusSVG
