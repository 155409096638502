import { DateTime, DurationLike, Interval } from 'luxon'
import { useToast } from 'native-base'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useLocation } from 'react-router-dom'

import client from '../apolloClient'
import { ToastAlert } from '../components/Instructions/Common/ToastAlert'
import { ProofOfServicePhoto } from '../components/Map/OrderMap'
import { Order, OrderServiceType, OrderStatus } from '../types/graphql'
import { AuthContext } from './AuthContextProvider'

interface AppContextValue {
  order?: Order
  setOrder: (order: Order) => void
  proofOfServicePhotos: ProofOfServicePhoto[]
  handleSetProofOfServicePhotos: (proofOfServicePhotos: ProofOfServicePhoto[]) => void
  clearOrder: () => void
}

const IDLE_INTERVAL = 900000 // 15 minutes
const INTERVAL_FROM_SIGN_IN: DurationLike = { minutes: 59, seconds: 59 }
const CHECK_SIGN_IN_INTERVAL = 900000 // 15 minutes

const AppDefaultState: AppContextValue = {
  order: {
    orderId: '',
    packages: [],
    reroutableZipCodes: [],
    serviceType: {} as OrderServiceType,
    address: {},
    clientDisplayName: '',
    isEditable: false,
    isPastConsumerRerouteCutoff: false,
    isPastRerouteCutoff: false,
    clientName: '',
    calculatedOrderInformation: { orderDeliveryDates: [], orderStatus: {} as OrderStatus },
    calculatedPackagesInformation: {
      deliveredPackages: {
        groupedByDeliveredAtTime: [],
        total: 0,
      },
      notDeliveredPackages: {
        groupedByDeliveryDate: [],
        groupedByDeliveryWindow: [],
        groupedByEveningPackages: [],
        groupedByMissingDeliveryDate: [],
        total: 0,
      },
      totalPackages: 0,
      notPickedUpPackages: {
        __typename: 'NotPickedUpPackages',
        groupedByEveningPackages: [],
        groupedByMissingPickUpDate: [],
        groupedByPickUpDate: [],
        groupedByPickUpWindow: [],
        total: 0,
      },
      pickedUpPackages: {
        __typename: 'PickedUpPackages',
        groupedByPickedUpAtTime: [],
        total: 0,
      },
    },
    isOrderReroutable: false,
    isOrderReroutableByConsumer: false,
  },
  setOrder: () => {},
  proofOfServicePhotos: [],
  handleSetProofOfServicePhotos: () => {},
  clearOrder: () => {},
}

export const AppContext = createContext<AppContextValue>(AppDefaultState)

function AppContextProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
  const location = useLocation()
  const toast = useToast()
  const { firebaseUser, logout } = useContext(AuthContext)
  const [order, setOrder] = useState({
    orderId: '',
    packages: [],
    reroutableZipCodes: [],
    serviceType: {} as OrderServiceType,
    address: {},
    clientDisplayName: '',
    isEditable: false,
    isPastConsumerRerouteCutoff: false,
    isPastRerouteCutoff: false,
    clientName: '',
    calculatedOrderInformation: { orderDeliveryDates: [], orderStatus: {} as OrderStatus },
    calculatedPackagesInformation: {
      deliveredPackages: {
        groupedByDeliveredAtTime: [],
        total: 0,
      },
      notDeliveredPackages: {
        groupedByDeliveryDate: [],
        groupedByDeliveryWindow: [],
        groupedByEveningPackages: [],
        groupedByMissingDeliveryDate: [],
        total: 0,
      },
      pickedUpPackages: {
        groupedByPickedUpAtTime: [],
        total: 0,
      },
      notPickedUpPackages: {
        groupedByPickUpDate: [],
        groupedByPickUpWindow: [],
        groupedByEveningPackages: [],
        groupedByMissingPickUpDate: [],
        total: 0,
      },
      totalPackages: 0,
    },
    isOrderReroutable: false,
    isOrderReroutableByConsumer: false,
  } as Order)
  const [proofOfServicePhotos, setProofOfServicePhotos] = useState<ProofOfServicePhoto[]>([])

  const handleSetProofOfServicePhotos = useCallback(
    (proofOfServicePhotoInput: ProofOfServicePhoto[]) => {
      setProofOfServicePhotos(proofOfServicePhotoInput)
    },
    [setProofOfServicePhotos]
  )

  const handleClearOrder = useCallback(() => {
    setOrder(AppDefaultState.order as Order)
  }, [setOrder])

  const checkSignSession = useCallback(() => {
    if (firebaseUser) {
      const pkgOrderTimezone = (order.packages[0]?.timeZone ?? order?.timeZone) as string
      const signInDateTime = DateTime.fromJSDate(new Date(firebaseUser.metadata.lastSignInTime as string)).setZone(
        pkgOrderTimezone
      )
      if (signInDateTime) {
        const intervalFromSignIn = DateTime.fromJSDate(new Date(firebaseUser.metadata.lastSignInTime as string))
          .setZone(pkgOrderTimezone)
          .plus(INTERVAL_FROM_SIGN_IN)
        const interval = Interval.fromDateTimes(signInDateTime, intervalFromSignIn)
        const now = DateTime.now().setZone((order.packages[0]?.timeZone ?? order?.timeZone) as string)
        if (!interval.contains(now)) {
          logout()
          client.refetchQueries({ include: 'all' })
          toast.show({
            placement: 'top',
            render: ({ id }) => (
              <ToastAlert
                id={id}
                title={'User logged out'}
                description={'You were logged out due to inactivity'}
                status={'warning'}
                variant={'subtle'}
                isClosable={false}
                closeToast={toast.close}
              />
            ),
          })
        }
      }
    }
  }, [order, firebaseUser, logout, toast])

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkSignSession()
    }, CHECK_SIGN_IN_INTERVAL)

    return () => clearInterval(intervalId)
  }, [checkSignSession])

  useEffect(() => {
    const handlePopState = () => {
      if (location?.pathname === '/') {
        handleClearOrder()
      }
    }
    window.addEventListener('popstate', handlePopState)

    return () => window.removeEventListener('popstate', handlePopState)
  }, [handleClearOrder, location?.pathname])

  const handleOnIdle = () => {
    if (firebaseUser) {
      logout()
      toast.show({
        placement: 'top',
        render: ({ id }) => (
          <ToastAlert
            id={id}
            title={'User logged out'}
            description={'You were logged out due to inactivity'}
            status={'warning'}
            variant={'subtle'}
            isClosable={false}
            closeToast={toast.close}
          />
        ),
      })
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const idleTimer = useIdleTimer({
    timeout: IDLE_INTERVAL,
    onIdle: handleOnIdle,
  })

  const state = useMemo(() => {
    return { order, setOrder, proofOfServicePhotos, handleSetProofOfServicePhotos, clearOrder: handleClearOrder }
  }, [order, setOrder, proofOfServicePhotos, handleSetProofOfServicePhotos, handleClearOrder])

  return <AppContext.Provider value={state}>{children}</AppContext.Provider>
}

export default AppContextProvider
