import { PropertyType } from '../../../types/graphql'

export enum PREFERRED_LOCATIONS {
  FrontDoor = 'Front Door',
  SidePorch = 'Side Porch',
  Garage = 'Garage',
  Mailroom = 'Mailroom',
  PropertyStaff = 'Property Staff',
  LoadingDock = 'Loading Dock',
  NoPreference = '',
  NotSet = 'Not Set',
}

export enum LOCATION_TYPE {
  Place,
  Person,
}

export interface PreferredLocation {
  value: PREFERRED_LOCATIONS
  type: LOCATION_TYPE
}

export const preferredLocations: Record<PREFERRED_LOCATIONS, LOCATION_TYPE> = {
  [PREFERRED_LOCATIONS.FrontDoor]: LOCATION_TYPE.Place,
  [PREFERRED_LOCATIONS.SidePorch]: LOCATION_TYPE.Place,
  [PREFERRED_LOCATIONS.Garage]: LOCATION_TYPE.Place,
  [PREFERRED_LOCATIONS.Mailroom]: LOCATION_TYPE.Place,
  [PREFERRED_LOCATIONS.PropertyStaff]: LOCATION_TYPE.Person,
  [PREFERRED_LOCATIONS.LoadingDock]: LOCATION_TYPE.Place,
  [PREFERRED_LOCATIONS.NoPreference]: LOCATION_TYPE.Place,
  [PREFERRED_LOCATIONS.NotSet]: LOCATION_TYPE.Place,
}

export const preferredLocationNotSet = PREFERRED_LOCATIONS.NotSet
export const preferredLocationsByPropertyType = {
  [PropertyType.House]: [
    PREFERRED_LOCATIONS.NoPreference,
    PREFERRED_LOCATIONS.FrontDoor,
    PREFERRED_LOCATIONS.SidePorch,
    PREFERRED_LOCATIONS.Garage,
  ],
  [PropertyType.Apartment]: [
    PREFERRED_LOCATIONS.NoPreference,
    PREFERRED_LOCATIONS.Mailroom,
    PREFERRED_LOCATIONS.PropertyStaff,
    PREFERRED_LOCATIONS.LoadingDock,
    PREFERRED_LOCATIONS.Garage,
    PREFERRED_LOCATIONS.FrontDoor,
    PREFERRED_LOCATIONS.SidePorch,
  ],
  [PropertyType.Business]: [
    PREFERRED_LOCATIONS.NoPreference,
    PREFERRED_LOCATIONS.Mailroom,
    PREFERRED_LOCATIONS.PropertyStaff,
    PREFERRED_LOCATIONS.LoadingDock,
    PREFERRED_LOCATIONS.Garage,
    PREFERRED_LOCATIONS.FrontDoor,
    PREFERRED_LOCATIONS.SidePorch,
  ],
  [PropertyType.Other]: [
    PREFERRED_LOCATIONS.NoPreference,
    PREFERRED_LOCATIONS.FrontDoor,
    PREFERRED_LOCATIONS.SidePorch,
    PREFERRED_LOCATIONS.Garage,
    PREFERRED_LOCATIONS.Mailroom,
    PREFERRED_LOCATIONS.PropertyStaff,
    PREFERRED_LOCATIONS.LoadingDock,
  ],
}

export function preferredLocationFromString(s: string | undefined): PREFERRED_LOCATIONS {
  switch (s) {
    case 'Front Door':
      return PREFERRED_LOCATIONS.FrontDoor
    case 'Side Porch':
      return PREFERRED_LOCATIONS.SidePorch
    case 'Garage':
      return PREFERRED_LOCATIONS.Garage
    case 'Mailroom':
      return PREFERRED_LOCATIONS.Mailroom
    case 'Property Staff':
      return PREFERRED_LOCATIONS.PropertyStaff
    case 'Loading Dock':
      return PREFERRED_LOCATIONS.LoadingDock
    default:
      return PREFERRED_LOCATIONS.NoPreference
  }
}
