import { useFormikContext } from 'formik'

import { AddressAttribute, OrderServiceType } from '../../../types/graphql'
import { ControlledSelect } from './Input/ControlledSelect'
import { InstructionsFormType } from './InstructionsForm'
import { PREFERRED_LOCATIONS, preferredLocationsByPropertyType } from './preferredLocations'

export interface PreferredLocationInputType {
  attributes?: AddressAttribute[]
  instructions?: string | null
  preferredLocation?: string
  pickupInstructions?: string | null
  preferredPickupLocation?: string
  securityCode?: string
}

const PREFERRED_LOCATION_LABEL: Record<PREFERRED_LOCATIONS, string> = {
  [PREFERRED_LOCATIONS.FrontDoor]: 'Front Door',
  [PREFERRED_LOCATIONS.SidePorch]: 'Side Porch',
  [PREFERRED_LOCATIONS.Garage]: 'Garage',
  [PREFERRED_LOCATIONS.Mailroom]: 'Mailroom',
  [PREFERRED_LOCATIONS.PropertyStaff]: 'Property Staff',
  [PREFERRED_LOCATIONS.LoadingDock]: 'Loading Dock',
  [PREFERRED_LOCATIONS.NoPreference]: 'No Preference',
  [PREFERRED_LOCATIONS.NotSet]: '',
}
const PREFERRED_LOCATION_INPUT_LABEL = {
  [OrderServiceType.Delivery]: 'Where should we leave your package?',
  [OrderServiceType.Pickup]: 'Where should we pickup your package?',
}

export interface PreferredLocationInputProps {
  serviceType: OrderServiceType
}

export const PreferredLocationInput = ({ serviceType }: PreferredLocationInputProps) => {
  const { values, isSubmitting } = useFormikContext<InstructionsFormType>()

  const name = serviceType === OrderServiceType.Pickup ? 'preferredPickupLocation' : 'preferredLocation'

  const preferredLocationOptions = [...(preferredLocationsByPropertyType[values.type] ?? [])].map(
    preferredLocation => ({
      value: preferredLocation,
      label: PREFERRED_LOCATION_LABEL[preferredLocation],
    })
  )

  return (
    <ControlledSelect name={name} label={PREFERRED_LOCATION_INPUT_LABEL[serviceType]} disabled={isSubmitting}>
      {preferredLocationOptions.map(preferredLocation => (
        <option key={`preferred-location-${preferredLocation.value}`} value={preferredLocation.value}>
          {preferredLocation.label}
        </option>
      ))}
    </ControlledSelect>
  )
}
