/* eslint-disable */
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  AWSDate: any
  AWSDateTime: any
  AWSEmail: any
  AWSIPAddress: any
  AWSJSON: any
  AWSPhone: any
  AWSTime: any
  AWSTimestamp: any
  AWSURL: any
  BigInt: any
  Double: any
}

export type Address = {
  __typename?: 'Address'
  addressId?: Maybe<Scalars['String']>
  apartment?: Maybe<Scalars['String']>
  attributes?: Maybe<Array<Maybe<AddressAttribute>>>
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  images?: Maybe<Array<Maybe<AddressImage>>>
  instructions?: Maybe<Scalars['String']>
  location?: Maybe<Location>
  name?: Maybe<Scalars['String']>
  pickupInstructions?: Maybe<Scalars['String']>
  preferredLocation?: Maybe<Scalars['String']>
  preferredPickupLocation?: Maybe<Scalars['String']>
  securityCode?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  type?: Maybe<PropertyType>
  zipCode?: Maybe<Scalars['String']>
}

export enum AddressAttribute {
  GatedCommunity = 'gatedCommunity',
  HasDog = 'hasDog',
  UseCodeToAccess = 'useCodeToAccess',
}

export type AddressImage = {
  __typename?: 'AddressImage'
  addressId: Scalars['String']
  createdAt?: Maybe<Scalars['AWSDateTime']>
  imageType: AddressImageType
  imageUrl?: Maybe<Scalars['String']>
  key: Scalars['String']
  location?: Maybe<ImageLocationData>
  meta?: Maybe<Scalars['String']>
  tags?: Maybe<Array<Maybe<Scalars['String']>>>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
}

export enum AddressImageType {
  PlacementPhoto = 'placementPhoto',
}

export type AddressInput = {
  existingAddress?: InputMaybe<ExistingAddressInput>
  newAddress?: InputMaybe<NewAddressInput>
}

export type CalculatedOrderInformation = {
  __typename?: 'CalculatedOrderInformation'
  isDeliveryByEvening?: Maybe<Scalars['Boolean']>
  orderDeliveryDates: Array<Scalars['String']>
  orderStatus: OrderStatus
}

export type CalculatedPackagesInformation = {
  __typename?: 'CalculatedPackagesInformation'
  deliveredPackages: DeliveredPackages
  notDeliveredPackages: NotDeliveredPackages
  notPickedUpPackages: NotPickedUpPackages
  pickedUpPackages: PickedUpPackages
  totalPackages: Scalars['Int']
}

export type Consumer = {
  __typename?: 'Consumer'
  agreedToTermsAndConditions?: Maybe<Scalars['Boolean']>
  consumerId: Scalars['ID']
  email?: Maybe<Scalars['String']>
  latestTermsVersionAgreedTo?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  notificationPreferences?: Maybe<NotificationPreference>
  phoneNumber?: Maybe<Scalars['String']>
  submittedAppReviewDate?: Maybe<Scalars['AWSDateTime']>
}

export type ConsumerMobileAppFeedback = {
  __typename?: 'ConsumerMobileAppFeedback'
  comment?: Maybe<Scalars['String']>
  customerId: Scalars['String']
  deviceId?: Maybe<Scalars['String']>
  feedback: Scalars['String']
  messageId?: Maybe<Scalars['String']>
  orderId?: Maybe<Scalars['String']>
  rating?: Maybe<Scalars['String']>
  timestamp?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type DeliveredPackages = {
  __typename?: 'DeliveredPackages'
  groupedByDeliveredAtTime: Array<Maybe<GroupedByDeliveredAtTime>>
  total: Scalars['Int']
}

export type DeliveryTimeWindow = {
  __typename?: 'DeliveryTimeWindow'
  endsAt?: Maybe<Scalars['AWSDateTime']>
  startsAt?: Maybe<Scalars['AWSDateTime']>
}

export type EventLog = {
  __typename?: 'EventLog'
  facility?: Maybe<Facility>
  message?: Maybe<Scalars['String']>
  meta?: Maybe<Scalars['String']>
  operation: PackageEventOperations
  timestamp?: Maybe<Scalars['AWSDateTime']>
}

export type ExistingAddressInput = {
  sourceAddressId: Scalars['String']
  targetAddressId: Scalars['String']
}

export type Facility = {
  __typename?: 'Facility'
  city: Scalars['String']
  facilityId: Scalars['String']
  state: Scalars['String']
}

export type GroupedByDeliveredAtTime = {
  __typename?: 'GroupedByDeliveredAtTime'
  deliveredAt: Scalars['String']
  trackingIds: Array<Scalars['String']>
}

export type GroupedByPickedUpAtTime = {
  __typename?: 'GroupedByPickedUpAtTime'
  pickedUpAt: Scalars['String']
  trackingIds: Array<Scalars['String']>
}

export type GroupedDeliveryDate = {
  __typename?: 'GroupedDeliveryDate'
  deliveryDate: Scalars['String']
  trackingIds: Array<Scalars['String']>
}

export type GroupedDeliveryWindow = {
  __typename?: 'GroupedDeliveryWindow'
  endsAt: Scalars['String']
  startsAt: Scalars['String']
  trackingIds: Array<Scalars['String']>
}

export type GroupedPickUpDate = {
  __typename?: 'GroupedPickUpDate'
  pickUpDate: Scalars['String']
  trackingIds: Array<Scalars['String']>
}

export type GroupedPickUpWindow = {
  __typename?: 'GroupedPickUpWindow'
  endsAt: Scalars['String']
  startsAt: Scalars['String']
  trackingIds: Array<Scalars['String']>
}

export type ImageLocationData = {
  __typename?: 'ImageLocationData'
  lat?: Maybe<Scalars['Float']>
  lng?: Maybe<Scalars['Float']>
}

export type ImageLocationDataInput = {
  lat?: InputMaybe<Scalars['Float']>
  lng?: InputMaybe<Scalars['Float']>
}

export type ImageUploadUrl = {
  __typename?: 'ImageUploadUrl'
  imageUrl?: Maybe<Scalars['String']>
  key: Scalars['String']
  uploadUrl: Scalars['String']
}

export type Location = {
  __typename?: 'Location'
  lat: Scalars['Float']
  lng: Scalars['Float']
}

export type Mutation = {
  __typename?: 'Mutation'
  agreeToTermsAndConditions?: Maybe<Consumer>
  createTermsAndConditions?: Maybe<TermsAndConditions>
  editOrder?: Maybe<Order>
  registerPushToken?: Maybe<Scalars['Boolean']>
  removeAddressImage?: Maybe<AddressImage>
  revokePushToken?: Maybe<Scalars['Boolean']>
  submitConsumerMobileAppFeedback?: Maybe<ConsumerMobileAppFeedback>
  submittedAppReviewDate?: Maybe<Consumer>
  updateClientAddressInstructions?: Maybe<Address>
  updateClientAddressInstructionsForOrder?: Maybe<Address>
  upsertAddressImage?: Maybe<AddressImage>
  upsertNotificationPreference?: Maybe<NotificationPreference>
}

export type MutationCreateTermsAndConditionsArgs = {
  version?: InputMaybe<Scalars['String']>
}

export type MutationEditOrderArgs = {
  addressInput?: InputMaybe<NewAddressInput>
  orderId?: InputMaybe<Scalars['String']>
}

export type MutationRegisterPushTokenArgs = {
  platform?: InputMaybe<Scalars['String']>
  token: Scalars['String']
}

export type MutationRemoveAddressImageArgs = {
  addressId: Scalars['ID']
  imageType: AddressImageType
}

export type MutationRevokePushTokenArgs = {
  platform?: InputMaybe<Scalars['String']>
  token: Scalars['String']
}

export type MutationSubmitConsumerMobileAppFeedbackArgs = {
  customerId?: InputMaybe<Scalars['String']>
  deviceId?: InputMaybe<Scalars['String']>
  feedback?: InputMaybe<Scalars['String']>
}

export type MutationUpdateClientAddressInstructionsArgs = {
  addressId: Scalars['ID']
  clientId: Scalars['ID']
  updateInstructionsInput: UpdateInstructionsInput
}

export type MutationUpdateClientAddressInstructionsForOrderArgs = {
  orderId: Scalars['ID']
  updateInstructionsInput: UpdateInstructionsInput
}

export type MutationUpsertAddressImageArgs = {
  addressId: Scalars['ID']
  addressImageInput: UpsertAddressImageInput
  imageType: AddressImageType
}

export type MutationUpsertNotificationPreferenceArgs = {
  consumerId: Scalars['ID']
  notificationPreferenceInput: UpdateNotificationPreferenceInput
}

export type NewAddressInput = {
  apartment?: InputMaybe<Scalars['String']>
  attributes?: InputMaybe<Array<InputMaybe<AddressAttribute>>>
  city: Scalars['String']
  country?: InputMaybe<Scalars['String']>
  instructions?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  pickupInstructions?: InputMaybe<Scalars['String']>
  preferredLocation?: InputMaybe<Scalars['String']>
  preferredPickupLocation?: InputMaybe<Scalars['String']>
  securityCode?: InputMaybe<Scalars['String']>
  state: Scalars['String']
  street: Scalars['String']
  type?: InputMaybe<PropertyType>
  zipCode: Scalars['String']
}

export type NotDeliveredPackages = {
  __typename?: 'NotDeliveredPackages'
  groupedByDeliveryDate: Array<Maybe<GroupedDeliveryDate>>
  groupedByDeliveryWindow: Array<Maybe<GroupedDeliveryWindow>>
  groupedByEveningPackages: Array<Maybe<Scalars['String']>>
  groupedByMissingDeliveryDate: Array<Maybe<Scalars['String']>>
  total: Scalars['Int']
}

export type NotPickedUpPackages = {
  __typename?: 'NotPickedUpPackages'
  groupedByEveningPackages: Array<Maybe<Scalars['String']>>
  groupedByMissingPickUpDate: Array<Maybe<Scalars['String']>>
  groupedByPickUpDate: Array<Maybe<GroupedPickUpDate>>
  groupedByPickUpWindow: Array<Maybe<GroupedPickUpWindow>>
  total: Scalars['Int']
}

export type NotificationPreference = {
  __typename?: 'NotificationPreference'
  channel?: Maybe<Array<Maybe<PreferenceChannel>>>
  deletedAt?: Maybe<Scalars['AWSDateTime']>
  invalidSmsDestination?: Maybe<Scalars['Boolean']>
  optedOutViaCarrier?: Maybe<Scalars['Boolean']>
  updatedByEmail?: Maybe<Scalars['String']>
  updatedById?: Maybe<Scalars['String']>
  updatedByName?: Maybe<Scalars['String']>
}

export type Order = {
  __typename?: 'Order'
  address: Address
  alerts?: Maybe<Array<Scalars['String']>>
  authedUserOwnsOrder?: Maybe<Scalars['Boolean']>
  calculatedOrderInformation: CalculatedOrderInformation
  calculatedPackagesInformation: CalculatedPackagesInformation
  clientDisplayName: Scalars['String']
  clientId?: Maybe<Scalars['String']>
  clientName: Scalars['String']
  consumerId?: Maybe<Scalars['String']>
  deliveryDate?: Maybe<Scalars['String']>
  deliveryTimeWindow?: Maybe<DeliveryTimeWindow>
  facilityId?: Maybe<Scalars['String']>
  hasInstructions?: Maybe<Scalars['Boolean']>
  instructionEditsAllowed?: Maybe<Scalars['Boolean']>
  instructions?: Maybe<Scalars['String']>
  isEditable: Scalars['Boolean']
  isOrderReroutable: Scalars['Boolean']
  isOrderReroutableByConsumer: Scalars['Boolean']
  isPastConsumerRerouteCutoff: Scalars['Boolean']
  isPastRerouteCutoff: Scalars['Boolean']
  merchantId?: Maybe<Scalars['String']>
  merchantName?: Maybe<Scalars['String']>
  orderId: Scalars['ID']
  packages: Array<Package>
  reroutableZipCodes: Array<Scalars['String']>
  rerouteBlockedByClient?: Maybe<Scalars['Boolean']>
  serviceType: OrderServiceType
  timeZone?: Maybe<Scalars['String']>
}

export enum OrderServiceType {
  Delivery = 'delivery',
  Pickup = 'pickup',
}

export enum OrderStatus {
  Cancelled = 'cancelled',
  Created = 'created',
  Delivered = 'delivered',
  Discarded = 'discarded',
  DroppedOffAtVeho = 'droppedOffAtVeho',
  IssueWithOrder = 'issueWithOrder',
  Misdelivered = 'misdelivered',
  NoDeliveryDate = 'noDeliveryDate',
  NotFound = 'notFound',
  NotReceivedFromClient = 'notReceivedFromClient',
  OutForPickup = 'outForPickup',
  Pending = 'pending',
  PickedUpFromClient = 'pickedUpFromClient',
  PickedUpFromConsumer = 'pickedUpFromConsumer',
  PickedUpFromVeho = 'pickedUpFromVeho',
  PickupDroppedOffAtVeho = 'pickupDroppedOffAtVeho',
  PickupFailed = 'pickupFailed',
  PickupSkipped = 'pickupSkipped',
  PickupTransferredToExternalCarrier = 'pickupTransferredToExternalCarrier',
  Returned = 'returned',
  ReturnedToClient = 'returnedToClient',
  ReturnedToVeho = 'returnedToVeho',
  SeeMore = 'seeMore',
}

export enum OrderStatusType {
  IssueWithOrder = 'issueWithOrder',
  SeeMore = 'seeMore',
}

export type Package = {
  __typename?: 'Package'
  deliveryTimeWindow?: Maybe<DeliveryTimeWindow>
  eventLog: Array<EventLog>
  isPastConsumerRerouteCutoff: Scalars['Boolean']
  isPastRerouteCutoff: Scalars['Boolean']
  packageId: Scalars['ID']
  scheduledServiceDate?: Maybe<Scalars['AWSDateTime']>
  timeZone?: Maybe<Scalars['String']>
  trackingId: Scalars['String']
}

export enum PackageEventOperations {
  PackageArrivedAtFacility = 'PackageArrivedAtFacility',
  PackageDelayed = 'PackageDelayed',
  PackageDepartedFromFacility = 'PackageDepartedFromFacility',
  PackageHadDeliveryIssue = 'PackageHadDeliveryIssue',
  Cancelled = 'cancelled',
  Created = 'created',
  Delivered = 'delivered',
  Discarded = 'discarded',
  DroppedOffAtVeho = 'droppedOffAtVeho',
  HadDeliveryIssue = 'hadDeliveryIssue',
  Misdelivered = 'misdelivered',
  NotFound = 'notFound',
  NotReceivedFromClient = 'notReceivedFromClient',
  OutForPickup = 'outForPickup',
  Pending = 'pending',
  PickedUpFromClient = 'pickedUpFromClient',
  PickedUpFromConsumer = 'pickedUpFromConsumer',
  PickedUpFromVeho = 'pickedUpFromVeho',
  PickupDroppedOffAtVeho = 'pickupDroppedOffAtVeho',
  PickupFailed = 'pickupFailed',
  PickupSkipped = 'pickupSkipped',
  PickupTransferredToExternalCarrier = 'pickupTransferredToExternalCarrier',
  Returned = 'returned',
  ReturnedToClient = 'returnedToClient',
  ReturnedToVeho = 'returnedToVeho',
}

export type PickedUpPackages = {
  __typename?: 'PickedUpPackages'
  groupedByPickedUpAtTime: Array<Maybe<GroupedByPickedUpAtTime>>
  total: Scalars['Int']
}

export enum PreferenceChannel {
  Push = 'push',
  Sms = 'sms',
}

export type ProofOfServicePhotoUrl = {
  __typename?: 'ProofOfServicePhotoUrl'
  location?: Maybe<Location>
  packageId: Scalars['ID']
  url?: Maybe<Scalars['String']>
}

export enum PropertyType {
  Apartment = 'APARTMENT',
  Business = 'BUSINESS',
  House = 'HOUSE',
  Other = 'OTHER',
}

export type Query = {
  __typename?: 'Query'
  getConsumer?: Maybe<Consumer>
  getConsumerAddressImageUploadUrl?: Maybe<ImageUploadUrl>
  getConsumerOrders?: Maybe<Array<Maybe<Order>>>
  getImageUrl?: Maybe<Scalars['String']>
  getNotificationPreference?: Maybe<NotificationPreference>
  getOrderByTrackingId?: Maybe<Order>
  getOrderByTrackingIdCWA?: Maybe<Order>
  getOrdersByTrackingIds?: Maybe<Array<Maybe<Order>>>
  getProofOfServicePhotoUrls: Array<ProofOfServicePhotoUrl>
  isOrderAssociatedWithPhoneNumber?: Maybe<Scalars['Boolean']>
}

export type QueryGetConsumerAddressImageUploadUrlArgs = {
  addressId: Scalars['String']
  fileName: Scalars['String']
  imageType: AddressImageType
}

export type QueryGetConsumerOrdersArgs = {
  includePickups?: InputMaybe<Scalars['Boolean']>
}

export type QueryGetImageUrlArgs = {
  key: Scalars['String']
}

export type QueryGetNotificationPreferenceArgs = {
  consumerId: Scalars['ID']
}

export type QueryGetOrderByTrackingIdArgs = {
  includePickups?: InputMaybe<Scalars['Boolean']>
  trackingId: Scalars['String']
}

export type QueryGetOrderByTrackingIdCwaArgs = {
  includePickups?: InputMaybe<Scalars['Boolean']>
  trackingId: Scalars['String']
}

export type QueryGetOrdersByTrackingIdsArgs = {
  includePickups?: InputMaybe<Scalars['Boolean']>
  trackingIds: Array<InputMaybe<Scalars['String']>>
}

export type QueryGetProofOfServicePhotoUrlsArgs = {
  packageIds: Array<Scalars['ID']>
}

export type QueryIsOrderAssociatedWithPhoneNumberArgs = {
  orderId: Scalars['String']
  phone: Scalars['String']
}

export type TermsAndConditions = {
  __typename?: 'TermsAndConditions'
  createdAt?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['String']>
}

export type UpdateInstructionsInput = {
  attributes?: InputMaybe<Array<InputMaybe<AddressAttribute>>>
  instructions?: InputMaybe<Scalars['String']>
  pickupInstructions?: InputMaybe<Scalars['String']>
  preferredLocation?: InputMaybe<Scalars['String']>
  preferredPickupLocation?: InputMaybe<Scalars['String']>
  securityCode?: InputMaybe<Scalars['String']>
  type?: InputMaybe<PropertyType>
}

export type UpdateNotificationPreferenceInput = {
  channel: Array<InputMaybe<PreferenceChannel>>
  consumerId: Scalars['String']
  invalidSmsDestination: Scalars['Boolean']
  optedOutViaCarrier: Scalars['Boolean']
}

export type UpsertAddressImageInput = {
  key: Scalars['String']
  location?: InputMaybe<ImageLocationDataInput>
  meta?: InputMaybe<Scalars['String']>
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type AgreeToTermsAndConditionsMutationVariables = Exact<{ [key: string]: never }>

export type AgreeToTermsAndConditionsMutation = {
  __typename?: 'Mutation'
  agreeToTermsAndConditions?: { __typename?: 'Consumer'; agreedToTermsAndConditions?: boolean | null } | null
}

export type GetOrderByTrackingIdCwaQueryVariables = Exact<{
  trackingId: Scalars['String']
}>

export type GetOrderByTrackingIdCwaQuery = {
  __typename?: 'Query'
  order?: {
    __typename?: 'Order'
    alerts?: Array<string> | null
    clientName: string
    clientDisplayName: string
    deliveryDate?: string | null
    orderId: string
    timeZone?: string | null
    serviceType: OrderServiceType
    instructionEditsAllowed?: boolean | null
    hasInstructions?: boolean | null
    address: {
      __typename?: 'Address'
      city?: string | null
      country?: string | null
      state?: string | null
      zipCode?: string | null
      type?: PropertyType | null
      instructions?: string | null
      preferredLocation?: string | null
      pickupInstructions?: string | null
      preferredPickupLocation?: string | null
      attributes?: Array<AddressAttribute | null> | null
      securityCode?: string | null
    }
    calculatedOrderInformation: {
      __typename?: 'CalculatedOrderInformation'
      orderDeliveryDates: Array<string>
      isDeliveryByEvening?: boolean | null
    }
    calculatedPackagesInformation: {
      __typename?: 'CalculatedPackagesInformation'
      totalPackages: number
      deliveredPackages: {
        __typename?: 'DeliveredPackages'
        total: number
        groupedByDeliveredAtTime: Array<{
          __typename?: 'GroupedByDeliveredAtTime'
          deliveredAt: string
          trackingIds: Array<string>
        } | null>
      }
      notDeliveredPackages: {
        __typename?: 'NotDeliveredPackages'
        groupedByEveningPackages: Array<string | null>
        groupedByMissingDeliveryDate: Array<string | null>
        total: number
        groupedByDeliveryDate: Array<{
          __typename?: 'GroupedDeliveryDate'
          deliveryDate: string
          trackingIds: Array<string>
        } | null>
        groupedByDeliveryWindow: Array<{
          __typename?: 'GroupedDeliveryWindow'
          endsAt: string
          startsAt: string
          trackingIds: Array<string>
        } | null>
      }
      notPickedUpPackages: {
        __typename?: 'NotPickedUpPackages'
        groupedByPickUpDate: Array<{
          __typename?: 'GroupedPickUpDate'
          pickUpDate: string
          trackingIds: Array<string>
        } | null>
        groupedByPickUpWindow: Array<{
          __typename?: 'GroupedPickUpWindow'
          endsAt: string
          startsAt: string
          trackingIds: Array<string>
        } | null>
      }
    }
    packages: Array<{
      __typename?: 'Package'
      packageId: string
      trackingId: string
      timeZone?: string | null
      deliveryTimeWindow?: { __typename?: 'DeliveryTimeWindow'; endsAt?: any | null; startsAt?: any | null } | null
      eventLog: Array<{
        __typename?: 'EventLog'
        operation: PackageEventOperations
        timestamp?: any | null
        message?: string | null
        facility?: { __typename?: 'Facility'; facilityId: string; city: string; state: string } | null
      }>
    }>
  } | null
}

export type GetProofOfServiceUrlsQueryVariables = Exact<{
  packageIds: Array<Scalars['ID']> | Scalars['ID']
}>

export type GetProofOfServiceUrlsQuery = {
  __typename?: 'Query'
  proofOfServicePhotoUrls: Array<{ __typename?: 'ProofOfServicePhotoUrl'; packageId: string; url?: string | null }>
}

export type OrderFieldsFragment = {
  __typename?: 'Order'
  alerts?: Array<string> | null
  clientName: string
  clientDisplayName: string
  deliveryDate?: string | null
  orderId: string
  timeZone?: string | null
  serviceType: OrderServiceType
  instructionEditsAllowed?: boolean | null
  hasInstructions?: boolean | null
  address: {
    __typename?: 'Address'
    city?: string | null
    country?: string | null
    state?: string | null
    zipCode?: string | null
    type?: PropertyType | null
    instructions?: string | null
    preferredLocation?: string | null
    pickupInstructions?: string | null
    preferredPickupLocation?: string | null
    attributes?: Array<AddressAttribute | null> | null
    securityCode?: string | null
  }
  calculatedOrderInformation: {
    __typename?: 'CalculatedOrderInformation'
    orderDeliveryDates: Array<string>
    isDeliveryByEvening?: boolean | null
  }
  calculatedPackagesInformation: {
    __typename?: 'CalculatedPackagesInformation'
    totalPackages: number
    deliveredPackages: {
      __typename?: 'DeliveredPackages'
      total: number
      groupedByDeliveredAtTime: Array<{
        __typename?: 'GroupedByDeliveredAtTime'
        deliveredAt: string
        trackingIds: Array<string>
      } | null>
    }
    notDeliveredPackages: {
      __typename?: 'NotDeliveredPackages'
      groupedByEveningPackages: Array<string | null>
      groupedByMissingDeliveryDate: Array<string | null>
      total: number
      groupedByDeliveryDate: Array<{
        __typename?: 'GroupedDeliveryDate'
        deliveryDate: string
        trackingIds: Array<string>
      } | null>
      groupedByDeliveryWindow: Array<{
        __typename?: 'GroupedDeliveryWindow'
        endsAt: string
        startsAt: string
        trackingIds: Array<string>
      } | null>
    }
    notPickedUpPackages: {
      __typename?: 'NotPickedUpPackages'
      groupedByPickUpDate: Array<{
        __typename?: 'GroupedPickUpDate'
        pickUpDate: string
        trackingIds: Array<string>
      } | null>
      groupedByPickUpWindow: Array<{
        __typename?: 'GroupedPickUpWindow'
        endsAt: string
        startsAt: string
        trackingIds: Array<string>
      } | null>
    }
  }
  packages: Array<{
    __typename?: 'Package'
    packageId: string
    trackingId: string
    timeZone?: string | null
    deliveryTimeWindow?: { __typename?: 'DeliveryTimeWindow'; endsAt?: any | null; startsAt?: any | null } | null
    eventLog: Array<{
      __typename?: 'EventLog'
      operation: PackageEventOperations
      timestamp?: any | null
      message?: string | null
      facility?: { __typename?: 'Facility'; facilityId: string; city: string; state: string } | null
    }>
  }>
}

export type UpdateClientAddressInstructionsForOrderMutationVariables = Exact<{
  orderId: Scalars['ID']
  updateInstructionsInput: UpdateInstructionsInput
}>

export type UpdateClientAddressInstructionsForOrderMutation = {
  __typename?: 'Mutation'
  updateClientAddressInstructionsForOrder?: {
    __typename?: 'Address'
    instructions?: string | null
    preferredLocation?: string | null
    pickupInstructions?: string | null
    preferredPickupLocation?: string | null
    securityCode?: string | null
    attributes?: Array<AddressAttribute | null> | null
  } | null
}
