import { ApolloClient } from '@apollo/client'

import { cache, link } from './utils/apollo'

const client = new ApolloClient({
  link,
  cache,
})

export default client
