import Svg, { Path } from 'react-native-svg'

const WarningSVG = ({ fill = '#665632' }: { fill?: string }) => {
  return (
    <Svg width={25} height={25} fill="none" viewBox="0 0 25 25">
      <Path d="M13.5 10.3809H11.5V15.3809H13.5V10.3809Z" fill={fill} />
      <Path d="M13.5 16.3809H11.5V18.3809H13.5V16.3809Z" fill={fill} />
      <Path
        d="M12.5 6.37086L20.03 19.3809H4.97L12.5 6.37086ZM12.5 2.38086L1.5 21.3809H23.5L12.5 2.38086Z"
        fill={fill}
      />
    </Svg>
  )
}

export default WarningSVG
