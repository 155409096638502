import { useFormikContext } from 'formik'
import { Button } from 'native-base'

import { trackEvent } from '../../../utils/analytics-v2'
import { ModalFormType } from '../InstructionsModal'

export type AddInstructionsLaterButtonProps = {
  closeModal: () => void
  cancelText?: string
}

export const AddInstructionsLaterButton = ({ closeModal, cancelText }: AddInstructionsLaterButtonProps) => {
  const { resetForm } = useFormikContext<ModalFormType>()
  const onPress = () => {
    closeModal()
    resetForm()
    trackEvent('dismiss_instructions', {})
  }

  return (
    <Button onPress={onPress} variant="ghost" _text={{ color: 'black' }}>
      {cancelText || 'Add Instructions Later'}
    </Button>
  )
}
