import { Box, Button } from 'native-base'
import { useCallback, useContext } from 'react'

import { AuthContext } from '../../context/AuthContextProvider'
import { OverlayState } from '../../context/OverlayContextProvider'
import { colors } from '../../utils/theme/configureTheme'

export const LoginButton = ({ orderHasPiiFields = false }: { orderHasPiiFields?: boolean }) => {
  const { handleSetStep, toggleModal } = useContext(OverlayState)
  const { firebaseUser, logout } = useContext(AuthContext)

  const handleOnPress = useCallback(() => {
    if (firebaseUser) {
      logout()
      return
    }

    handleSetStep(1)
    toggleModal({ showModal: true, auth_flow: 'general' })
  }, [handleSetStep, toggleModal, firebaseUser, logout])

  return (
    <Box>
      <Button size="sm" backgroundColor={colors.brand.black} onPress={handleOnPress}>
        {firebaseUser || orderHasPiiFields ? 'Logout' : 'Login'}
      </Button>
    </Box>
  )
}
