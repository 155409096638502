import Svg, { Path } from 'react-native-svg'

const PhoneFormVerifySVG = () => {
  return (
    <Svg width={80} height={80} fill="#a7ceeb" viewBox="0 0 256 256">
      <Path d="M248 64v128a8 8 0 0 1-8 8H40V56h200a8 8 0 0 1 8 8Z" opacity={0.2} />
      <Path d="M48 56v144a8 8 0 0 1-16 0V56a8 8 0 0 1 16 0Zm84 54.5-20 6.5V96a8 8 0 0 0-16 0v21l-20-6.5a8 8 0 0 0-5 15.22l20 6.49-12.34 17a8 8 0 1 0 12.94 9.4l12.34-17 12.34 17a8 8 0 1 0 12.94-9.4l-12.34-17 20-6.49A8 8 0 0 0 132 110.5Zm106 5.14a8 8 0 0 0-10-5.14l-20 6.5V96a8 8 0 0 0-16 0v21l-20-6.49a8 8 0 0 0-4.95 15.22l20 6.49-12.34 17a8 8 0 1 0 12.94 9.4l12.34-17 12.34 17a8 8 0 1 0 12.94-9.4l-12.34-17 20-6.49a8 8 0 0 0 5.07-10.09Z" />
    </Svg>
  )
}

export default PhoneFormVerifySVG
