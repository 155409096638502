import { useMemo, useState } from 'react'

import { StatsigClient } from '@statsig/js-client'

function coalesceEnvToStatsigEnv(env?: string): string | undefined {
  switch (env) {
    case 'production':
    case 'prod':
      return 'production'
    case 'staging':
    case 'stage':
    case 'local':
      return 'staging'
    case 'development':
    case 'dev':
      return 'development'
    default:
      return env
  }
}

export function useAsyncStatsigClient(): { isLoading: boolean; client: StatsigClient } {
  const [isLoading, setIsLoading] = useState(true)

  const client = useMemo(() => {
    const instance = new StatsigClient(
      `${process.env.REACT_APP_STATSIG_CLIENT_SDK_KEY}`,
      {},
      { environment: { tier: coalesceEnvToStatsigEnv(process.env.REACT_APP_STAGE) } }
    )

    instance
      .initializeAsync()
      .then(() => {
        setIsLoading(false)
      })
      .catch(err => {
        console.error(err)
      })

    return instance
  }, [])

  return { client, isLoading }
}
