import { Modal } from 'native-base'

import { APIProvider } from '@vis.gl/react-google-maps'

import { IOverlayState } from '../../context/OverlayContextProvider'
import { Order } from '../../types/graphql'
import { OrderMap, ProofOfServicePhoto } from './OrderMap'

interface IMapModalProps {
  isOpen: boolean
  onClose: () => void | undefined
  toggleMap: () => void | undefined
  order: Order
  proofOfServicePhotos?: ProofOfServicePhoto[]
  userAuthed?: boolean
  toggleModal: IOverlayState['toggleModal']
}

export const MapModal = ({
  isOpen,
  onClose,
  toggleMap,
  order,
  toggleModal,
  proofOfServicePhotos,
  userAuthed,
}: IMapModalProps) => {
  const handleAddInstructions = () => {
    toggleMap()
    toggleModal({ showModal: true, auth_flow: 'instructions' })
  }

  const handleEditAddress = () => {
    toggleMap()
    toggleModal({ showModal: true, auth_flow: 'edit_address' })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}>
        <OrderMap
          order={order as Order}
          proofOfServicePhotos={proofOfServicePhotos}
          userAuthed={userAuthed}
          fullScreenMap={true}
          toggleMap={toggleMap}
          orderMapStyles={{
            orderMapContainer: {
              position: 'absolute',
              height: '100%',
              zIndex: 2000,
              width: '100%',
              right: '7.5%',
            },
            mapContainer: {
              height: '100%',
              borderRadius: 0,
              overflow: 'show',
              width: '120%',
            },
          }}
          addInstructions={handleAddInstructions}
          editAddress={handleEditAddress}
        />
      </APIProvider>
    </Modal>
  )
}
