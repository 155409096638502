import { DateTime, Interval } from 'luxon'
// useScreenSize.js
import { useEffect, useState } from 'react'

import packageEvents from '../enums/package-events'
import serviceTypes from '../enums/service-types'
import {
  Address,
  CalculatedOrderInformation,
  CalculatedPackagesInformation,
  EventLog,
  Maybe,
  Package,
} from '../types/graphql'

const NEW_LINE = '\n'

export const sortedList = (packageDetails: Package) => {
  if (packageDetails && packageDetails.eventLog) {
    //filter added for CSR-620
    return [...packageDetails.eventLog]
      .sort(sortBy)
      .filter(eventLog => eventLog.operation !== packageEvents.PICKUP_DROPPED_OFF_AT_VEHO)
  }
  return []
}

export const sortBy = (a: EventLog, b: EventLog) => {
  if (a.timestamp < b.timestamp) {
    return 1
  }
  if (a.timestamp > b.timestamp) {
    return -1
  }
  return 0
}

export const formatDate = (input: string) => {
  return DateTime.fromISO(input).toLocaleString(DateTime.DATETIME_FULL)
}

export const getTitle = (
  isPreDeliveryWindowStage: boolean,
  isWithinDeliveryWindowStage: boolean,
  isPostDeliveryWindowStage: boolean,
  serviceType: string
) => {
  if (isPreDeliveryWindowStage && serviceType === serviceTypes.PICKUP) {
    return 'Picking up on'
  }
  if (isPreDeliveryWindowStage && serviceType === serviceTypes.DELIVERY) {
    return 'Delivering on'
  }
  if (isWithinDeliveryWindowStage && serviceType === serviceTypes.PICKUP) {
    return 'Order pickup window'
  }
  if (isWithinDeliveryWindowStage && serviceType === serviceTypes.DELIVERY) {
    return 'Order delivery window'
  }
  if (isPostDeliveryWindowStage && serviceType === serviceTypes.PICKUP) {
    return 'Picked up on'
  }
  if (isPostDeliveryWindowStage && serviceType === serviceTypes.DELIVERY) {
    return 'Delivered on'
  }
}

export const formatDeliveryDate = (deliveryDate?: string) => {
  if (!deliveryDate) {
    return 'Delivery Date to be Confirmed'
  }
  return DateTime.fromISO(deliveryDate).toLocaleString(DateTime.DATE_FULL)
}

export const getTimeRangeString = (start: string, end: string) => {
  const startDate = DateTime.fromISO(start)
  const endDate = DateTime.fromISO(end)
  const format = endDate.hasSame(startDate, 'day') ? 't' : 'DD'
  if (format === 't') {
    return `${startDate.toFormat('DD')}, ${Interval.fromDateTimes(startDate, endDate).toFormat(format)}`
  }
  return Interval.fromDateTimes(startDate, endDate).toFormat(format)
}

export const formatDeliveryWindow = (calculatedOrderInformation: CalculatedOrderInformation) => {
  const { orderDeliveryDates, isDeliveryByEvening } = calculatedOrderInformation
  if (orderDeliveryDates[0] && orderDeliveryDates[1]) {
    // Delivery time window with a startsAt and endsAt
    return getTimeRangeString(orderDeliveryDates[0], orderDeliveryDates[1])
  } else if (orderDeliveryDates[0]) {
    // Delivery date (order's delivery date, deliveryTimeWindow doesn't exist yet)
    return DateTime.fromISO(orderDeliveryDates[0]).toFormat('DD')
  } else {
    return isDeliveryByEvening ? 'Your package will be delivered this evening' : 'Delivery Date to be Confirmed'
  }
}

export const formatNotYetDeliveredPackageInfo = (
  calculatedPackagesInformation: CalculatedPackagesInformation,
  timeZone?: Maybe<string>
) => {
  const { notDeliveredPackages, notPickedUpPackages } = calculatedPackagesInformation
  let { groupedByDeliveryWindow, groupedByEveningPackages, groupedByMissingDeliveryDate, groupedByDeliveryDate } =
    notDeliveredPackages
  let { groupedByPickUpDate, groupedByPickUpWindow } = notPickedUpPackages

  // Build packages delivery by evening string
  let deliveryByEveningString = ''
  if (groupedByEveningPackages?.length > 0) {
    deliveryByEveningString = appendTrackingIdsToMessage(
      'Your package will be delivered this evening',
      groupedByEveningPackages as string[]
    )
  }

  // Sort and build packages with delivery window string
  const tmpGroupedByDeliveryWindow = [...groupedByDeliveryWindow]
  tmpGroupedByDeliveryWindow.sort(
    (a, b) => DateTime.fromISO(a!.startsAt).toMillis() - DateTime.fromISO(b!.startsAt).toMillis()
  )
  const deliveryByWindowString = groupedByDeliveryWindow.reduce((acc, deliveryWindowGroup, index) => {
    if (deliveryWindowGroup) {
      const localizedWindow = getTimeRangeString(deliveryWindowGroup.startsAt, deliveryWindowGroup.endsAt)
      let groupString = appendTrackingIdsToMessage(localizedWindow, deliveryWindowGroup.trackingIds)
      acc += groupString
      if (index !== groupedByDeliveryWindow.length - 1) {
        acc += NEW_LINE
      }
    }
    return acc
  }, '')

  // Sort and build packages with pickup window string
  const tmpGroupedByPickUpWindow = [...groupedByPickUpWindow]
  tmpGroupedByPickUpWindow.sort(
    (a, b) => DateTime.fromISO(a!.startsAt).toMillis() - DateTime.fromISO(b!.startsAt).toMillis()
  )
  const pickUpByWindowString = groupedByPickUpWindow.reduce((acc, deliveryWindowGroup, index) => {
    if (deliveryWindowGroup) {
      const localizedWindow = getTimeRangeString(deliveryWindowGroup.startsAt, deliveryWindowGroup.endsAt)
      let groupString = appendTrackingIdsToMessage(localizedWindow, deliveryWindowGroup.trackingIds)
      acc += groupString
      if (index !== groupedByDeliveryWindow.length - 1) {
        acc += NEW_LINE
      }
    }
    return acc
  }, '')

  // Build packages missing delivery info
  let deliveryByMissingString = ''
  if (groupedByMissingDeliveryDate?.length > 0) {
    deliveryByMissingString = appendTrackingIdsToMessage(
      'Delivery Date to be Confirmed',
      groupedByMissingDeliveryDate as string[]
    )
  }

  // sort and build packages with delivery date
  const tmpGroupedByDeliveryDate = [...groupedByDeliveryDate]
  tmpGroupedByDeliveryDate.sort(
    (a, b) => DateTime.fromISO(a!.deliveryDate).toMillis() - DateTime.fromISO(b!.deliveryDate).toMillis()
  )
  const deliveryByDateString = tmpGroupedByDeliveryDate.reduce((acc, deliveryDateGroup, index) => {
    if (deliveryDateGroup) {
      const localizedDate = DateTime.fromISO(deliveryDateGroup.deliveryDate, { zone: timeZone || undefined }).toFormat(
        'DD'
      )
      const groupString = appendTrackingIdsToMessage(localizedDate, deliveryDateGroup.trackingIds)
      acc += groupString
      if (index !== tmpGroupedByDeliveryDate.length - 1) {
        acc += NEW_LINE
      }
    }
    return acc
  }, '')

  // sort and build packages with pickup date
  const tmpGroupedByPickUpDate = [...groupedByPickUpDate]
  tmpGroupedByPickUpDate.sort(
    (a, b) => DateTime.fromISO(a!.pickUpDate).toMillis() - DateTime.fromISO(b!.pickUpDate).toMillis()
  )
  const pickUpByDateString = tmpGroupedByPickUpDate.reduce((acc, deliveryDateGroup, index) => {
    if (deliveryDateGroup) {
      const localizedDate = DateTime.fromISO(deliveryDateGroup.pickUpDate, { zone: timeZone || undefined }).toFormat(
        'DD'
      )
      const groupString = appendTrackingIdsToMessage(localizedDate, deliveryDateGroup.trackingIds)
      acc += groupString
      if (index !== tmpGroupedByDeliveryDate.length - 1) {
        acc += NEW_LINE
      }
    }
    return acc
  }, '')

  // construct the string from more specific to less specific delivery info
  const deliveryLines = [
    deliveryByEveningString,
    deliveryByWindowString,
    pickUpByWindowString,
    deliveryByDateString,
    pickUpByDateString,
    deliveryByMissingString,
  ].filter(line => line.length > 0)
  let displayString = deliveryLines.reduce((acc, line, index) => {
    return `${acc}${line}${index === deliveryLines.length - 1 ? '' : NEW_LINE}`
  }, '')
  // if there is only one display string line this means that all packages
  // have the same delivery info so no need to display tracking info
  const displayStringLines = displayString.split(NEW_LINE)
  if (displayStringLines.length === 1) {
    displayString = displayString.split('(')[0]
  }

  return displayString
}

export const formatDeliveredPackageInfo = (calculatedPackagesInformation: CalculatedPackagesInformation) => {
  const { deliveredPackages } = calculatedPackagesInformation
  const deliveredAtGroups = deliveredPackages?.groupedByDeliveredAtTime
  if (!deliveredAtGroups || deliveredAtGroups.length === 0) {
    return 'View order details below for more specific information'
  }

  if (deliveredAtGroups.length === 1) {
    return formatDate(deliveredAtGroups[0]!.deliveredAt)
  }

  // show the latest delivery time
  return formatDate(deliveredAtGroups[deliveredAtGroups.length - 1]!.deliveredAt)
}

export const formatAddress = (address: Address) => {
  if (!address) {
    return '-'
  }
  return `${address.city ?? ''}, ${address.state ?? ''} ${address.zipCode ?? ''}`
}

export const appendTrackingIdsToMessage = (message: string, trackingIds: string[] | string | null): string => {
  if (trackingIds instanceof Array) {
    return message + '\t (' + trackingIds.join(', ') + ')'
  }
  return trackingIds ? message + '\t (' + trackingIds + ')' : message
}

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return screenSize
}
