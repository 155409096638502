import { createContext, useCallback, useContext, useMemo, useState } from 'react'

import InstructionsModal from '../components/Instructions/InstructionsModal'
import { MapModal } from '../components/Map/MapModal'
import { Order } from '../types/graphql'
import { AppContext } from './AppContextProvider'
import { AuthContext } from './AuthContextProvider'

export interface IOverlayState {
  modalOpen: boolean
  step: number
  toggleModal: ({ showModal, auth_flow }: ToggleModalFuncProps) => void
  handleSetStep: (stepNumber: number) => void
  showMapModal: boolean
  toggleMapModal: () => void
  authFlow: string
}

type ModalCustomProps = {
  modalHeaderText?: string
  modalBodyText?: string
  cancelText?: string
}

const defaultPhoneModalProps: ModalCustomProps = {
  modalHeaderText: 'Login to edit details',
  modalBodyText: 'We need to confirm who you are in order to make any changes to your delivery',
  cancelText: 'Cancel',
}

const defaultVerificationModalProps: ModalCustomProps = {
  modalHeaderText: 'Login to edit details',
  modalBodyText: 'We need to confirm who you are in order to make any changes to your delivery',
  cancelText: 'Cancel',
}

const termsAndConditionsDefaultAction = 'making any changes to the delivery'

const defaultSubmitModalProps: ModalCustomProps = {
  modalHeaderText: 'Loading...',
}

const modalPropsInstructionsFlow: ModalCustomProps = {
  modalHeaderText: 'Confirm Instructions',
  modalBodyText: 'We need to verify your phone number to add delivery instructions.',
  cancelText: 'Add Instructions Later',
}

const modalPropsSubmitInstructionsFlow: ModalCustomProps = {
  modalHeaderText: 'Submitting Instructions...',
}

const termsAndConditionsInstructionsAction = 'adding instructions'

const modalPropsEditAddressFlow: ModalCustomProps = {
  modalHeaderText: 'Edit Address',
  modalBodyText: 'We need to confirm who you are in order to make any changes to your address.',
  cancelText: 'Cancel',
}

const termsAndConditionsEditAddressAction = 'updating the delivery address'

type ToggleModalFuncProps = {
  showModal: boolean
  auth_flow: 'instructions' | 'edit_address' | 'general'
}

export const defaultOverlayState = {
  modalOpen: false,
  step: 0,
  toggleModal: ({ showModal, auth_flow }: ToggleModalFuncProps) => {},
  handleSetStep: (stepNumber: number) => {},
  showMapModal: false,
  toggleMapModal: () => {},
  authFlow: '',
}

export const OverlayState = createContext(defaultOverlayState)

function OverlayProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
  const { order, proofOfServicePhotos } = useContext(AppContext)
  const { firebaseUser } = useContext(AuthContext)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [showMapModal, setShowMapModal] = useState<boolean>(false)
  const [step, setStep] = useState<number>(0)
  const [phoneModalProps, setPhoneModalProps] = useState<ModalCustomProps>(defaultPhoneModalProps)
  const [verificationModalProps, setVerificationModalProps] = useState<ModalCustomProps>(defaultVerificationModalProps)
  const [termsConditionsAction, setTermsConditionsAction] = useState<string>(termsAndConditionsDefaultAction)
  const [submitModalProps, setSubmitModalProps] = useState<ModalCustomProps>(defaultSubmitModalProps)
  const [authFlow, setAuthFlow] = useState<ToggleModalFuncProps['auth_flow']>('general')

  const handleSetModalContent = useCallback((auth_flow: ToggleModalFuncProps['auth_flow']) => {
    switch (auth_flow) {
      case 'instructions':
        setTermsConditionsAction(termsAndConditionsInstructionsAction)
        setPhoneModalProps(modalPropsInstructionsFlow)
        setVerificationModalProps(modalPropsInstructionsFlow)
        setSubmitModalProps(modalPropsSubmitInstructionsFlow)
        setAuthFlow(auth_flow)
        break
      case 'edit_address':
        setTermsConditionsAction(termsAndConditionsEditAddressAction)
        setPhoneModalProps(modalPropsEditAddressFlow)
        setVerificationModalProps(modalPropsEditAddressFlow)
        setSubmitModalProps(defaultSubmitModalProps)
        setAuthFlow(auth_flow)
        break
      case 'general':
      default:
        setTermsConditionsAction(termsAndConditionsDefaultAction)
        setPhoneModalProps(defaultPhoneModalProps)
        setVerificationModalProps(defaultVerificationModalProps)
        setSubmitModalProps(defaultSubmitModalProps)
        setAuthFlow(auth_flow)
        break
    }
  }, [])

  /**
   * Handle toggling modal visibily and setting modal props (header text, body text, submit button text, etc)
   * @param showModal boolean - show or hide modal.
   * @param auth_flow string - used to dynamically update modal text and active step based on operation (instructions, edit address, general, etc).
   */
  const toggleModal = useCallback(
    ({ showModal, auth_flow }: { showModal: boolean; auth_flow: 'instructions' | 'edit_address' | 'general' }) => {
      handleSetModalContent(auth_flow)
      setAuthFlow(auth_flow)
      setStep(auth_flow === 'instructions' ? 0 : auth_flow === 'edit_address' && firebaseUser ? 6 : 1)
      setModalOpen(showModal)
    },
    [handleSetModalContent, firebaseUser, setAuthFlow]
  )

  const handleSetModal = useCallback((value: boolean) => {
    setModalOpen(value)
  }, [])

  const handleSetStep = useCallback((stepNumber: number) => {
    setStep(stepNumber)
  }, [])

  const toggleMapModal = useCallback(() => {
    setShowMapModal(!showMapModal)
  }, [showMapModal])

  const state = useMemo(() => {
    return {
      modalOpen,
      toggleModal,
      step,
      handleSetStep,
      showMapModal,
      toggleMapModal,
      authFlow,
    }
  }, [modalOpen, toggleModal, step, handleSetStep, toggleMapModal, showMapModal, authFlow])

  return (
    <OverlayState.Provider value={state}>
      <MapModal
        order={order as Order}
        proofOfServicePhotos={proofOfServicePhotos}
        isOpen={showMapModal}
        onClose={() => setShowMapModal(false)}
        toggleMap={toggleMapModal}
        toggleModal={toggleModal}
        userAuthed={!!firebaseUser}
      />
      <InstructionsModal
        order={order as Order}
        isOpen={modalOpen}
        setIsOpen={handleSetModal}
        setActiveStep={handleSetStep}
        activeStep={step}
        phoneModalProps={phoneModalProps}
        verificationModalProps={verificationModalProps}
        termsAndConditionsAction={termsConditionsAction}
        submitModalProps={submitModalProps}
        auth_flow={authFlow}
      />
      {children}
    </OverlayState.Provider>
  )
}

export default OverlayProvider
