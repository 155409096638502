import { Button, Heading, VStack } from 'native-base'

import { Order, OrderServiceType } from '../../types/graphql'
import { InstructionsRender } from './Common/InstructionsRender'

export type InstructionsDisplayBoxProps = {
  order: Order
  onPress: () => void
  children?: React.ReactNode
}

export const InstructionsDisplayBox = ({ order, onPress, children }: InstructionsDisplayBoxProps) => {
  const edit = !!(
    order.address.preferredLocation ||
    order.address.preferredPickupLocation ||
    order.address.instructions ||
    order.address.pickupInstructions ||
    order.address.securityCode ||
    order.address.attributes?.length
  )

  const heading = order.serviceType === OrderServiceType.Pickup ? 'Pickup Instructions' : 'Delivery Instructions'

  return edit ? (
    <VStack bgColor={'brand.lightGrey'} borderRadius={4} p={4} space={1}>
      <Heading size={'xs'} fontFamily={'bodyBold'}>
        {heading}
      </Heading>
      <InstructionsRender order={order} />
      <Button onPress={onPress} size={'sm'} variant={'instructions'} mt={1}>
        Edit Instructions
      </Button>
    </VStack>
  ) : children ? (
    <>{children}</>
  ) : (
    <Button onPress={onPress} size={'sm'} variant={'instructions'}>
      {order.hasInstructions ? 'Edit Delivery Instructions' : 'Add Delivery Instructions'}
    </Button>
  )
}
