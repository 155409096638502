import { initializeApp } from 'firebase/app'
import { AppCheck, ReCaptchaEnterpriseProvider, getToken, initializeAppCheck } from 'firebase/app-check'
import { Auth, RecaptchaVerifier, signInWithPhoneNumber as firebaseSignInWithPhoneNumber, getAuth } from 'firebase/auth'

import { logError } from './monitorClient'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
  projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
  storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
  appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
  measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`,
}

const isNotLocalDev = process.env.REACT_APP_STAGE !== 'local'

interface Firebase {
  appCheck: AppCheck
  auth: Auth
}

const initializeFirebase = (): Firebase => {
  if (isNotLocalDev) {
    // Only initialize Firebase if not a local dev env
    const app = initializeApp(firebaseConfig)
    const appCheck = initializeAppCheck(app, {
      provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_APPCHECK_SITE_KEY_ID || ''),
      isTokenAutoRefreshEnabled: true,
    })
    const auth = getAuth(app)
    return { appCheck, auth }
  } else {
    return { appCheck: {} as AppCheck, auth: {} as Auth }
  }
}
export const { appCheck, auth }: Firebase = initializeFirebase()

/**
 * Sign in to Firebase with provided phone number. Sends a verification code to the phone number,
 * which the user must then enter to complete the sign-in process.
 *
 * @param phoneNumber Phone number to sign in with via SMS.
 * @param recaptchaWrapperRef Wrapper around the div to attach the RecaptchaVerifier to.
 * @returns confirmation object from Firebase. This object is used to verify the code entered by the user.
 */
export const signInWithPhoneNumber = async (phoneNumber: string, recaptchaWrapperRef: HTMLDivElement) => {
  if (isNotLocalDev) {
    // Initialize the RecaptchaVerifier for Firebase phone authentication.
    // This requires an empty div with an id to attach the RecaptchaVerifier to, so we reset the div here to ensure it's empty.
    // The div must be loaded before calling this function.
    recaptchaWrapperRef.innerHTML = `<div id="recaptcha-container"></div>`
    const verifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
      },
      auth
    )
    return await firebaseSignInWithPhoneNumber(auth, phoneNumber, verifier)
  }
}

/**
 * Get authorization header from Firebase tokens. This is used to authenticate with the GQL server.
 * At minimum, the AppCheck token is used, but if the user is logged in then the User token is also included.
 *
 * If the app is running in a local dev environment, a debug token is used instead of calling AppCheck.
 *
 * @returns Authorization header for Firebase.
 */
export const getFirebaseAuthorizationHeader = async (): Promise<string> => {
  let appCheckTokenResponse
  try {
    if (isNotLocalDev) {
      appCheckTokenResponse = await getToken(appCheck, false)
    } else {
      appCheckTokenResponse = { token: process.env.REACT_APP_CHECK_DEBUG_TOKEN ?? '' }
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      logError(`Error obtaining AppCheck token`, { error: err })
    }
  }
  if (auth.currentUser) {
    const token = await auth.currentUser?.getIdToken()
    // return the headers to the context so httpLink can read them
    return `${appCheckTokenResponse?.token} ${token}`
  }
  return `${appCheckTokenResponse?.token}`
}
