import { HStack, Pressable, Text } from 'native-base'
import { Path, Svg } from 'react-native-svg'

import { trackEvent } from '../utils/analytics-v2'
import { colors } from '../utils/theme/configureTheme'

const SupportSVG = () => {
  return (
    <Svg width={14} height={15} fill="none">
      <Path
        fill={colors.brand.softBlack}
        d="M11.796 2.24A6.754 6.754 0 0 0 .25 7v3.5A1.75 1.75 0 0 0 2 12.25h1a1.75 1.75 0 0 0 1.75-1.75V8A1.75 1.75 0 0 0 3 6.25H1.802A5.253 5.253 0 0 1 7 1.75h.04a5.231 5.231 0 0 1 5.157 4.5H11A1.75 1.75 0 0 0 9.25 8v2.5A1.75 1.75 0 0 0 11 12.25h1.225a1.25 1.25 0 0 1-1.225 1H7.5a.75.75 0 1 0 0 1.5H11A2.753 2.753 0 0 0 13.75 12V7a6.709 6.709 0 0 0-1.954-4.76ZM3 7.75a.25.25 0 0 1 .25.25v2.5a.25.25 0 0 1-.25.25H2a.25.25 0 0 1-.25-.25V7.75H3Zm7.75 2.75V8a.25.25 0 0 1 .25-.25h1.25v3H11a.25.25 0 0 1-.25-.25Z"
      />
    </Svg>
  )
}

const SupportButton = () => {
  const openSupportMessage = () => {
    trackEvent('contact_support', { method: 'sms' })
    window.location.href = `sms:68953`
  }
  return (
    <Pressable
      alignItems="center"
      justifyContent="center"
      px={4}
      height={8}
      bg={colors.brand.lightGrey}
      borderRadius="full"
      mr={4}
      onPress={openSupportMessage}
      _pressed={{ backgroundColor: colors.brand.selectedBlue }}
    >
      <HStack alignItems="center">
        <Text color={colors.brand.softBlack} mr={2} fontSize="xs" fontWeight="semibold">
          Contact Support
        </Text>
        <SupportSVG />
      </HStack>
    </Pressable>
  )
}

export default SupportButton
