import { useFormikContext } from 'formik'

import { Order } from '../../../types/graphql'
import { ModalContent } from '../Common/ModalContent'
import { InstructionsForm, InstructionsFormType } from './InstructionsForm'

export type InstructionsFormModalContentProps = {
  order: Order
  closeModal: () => void
}

export const InstructionsFormModalContent = ({ order, closeModal }: InstructionsFormModalContentProps) => {
  const { isSubmitting, isValid, handleSubmit } = useFormikContext<InstructionsFormType>()

  return (
    <ModalContent
      headerMain={'Add Delivery Instructions'}
      headerSecondary={'Delivery instructions helps ensure your driver knows exactly where to deliver your package.'}
      submitText="Save My Instructions"
      onSubmit={handleSubmit}
      closeModal={closeModal}
      disabled={isSubmitting || !isValid}
      loading={isSubmitting}
    >
      <InstructionsForm order={order} />
    </ModalContent>
  )
}
