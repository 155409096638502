import { gql } from 'graphql-tag'

export const GET_PROOF_OF_SERVICE_URLS = gql`
  query GetProofOfServiceUrls($packageIds: [ID!]!) {
    proofOfServicePhotoUrls: getProofOfServicePhotoUrls(packageIds: $packageIds) {
      packageId
      url
      location {
        lat
        lng
      }
    }
  }
`
