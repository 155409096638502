import { Box, Divider, HStack, Heading, Text, VStack, View } from 'native-base'

const Privacy = () => {
  return (
    <View style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Box style={{ margin: 'auto' }} justifyContent="space-between" alignItems="center" width="50%">
        <Box>
          <Heading size="xl" paddingY={4}>
            Veho Package Recipient Privacy Notice
          </Heading>
          <Heading size="lg">Notice Effective Date: 05/15/2022</Heading>
          <Text marginY={4} fontSize={14}>
            At Veho, we put humans at the center. Therefore, we want to empower you, our customers, to trust us to do
            the right thing with your information with this Privacy Notice. This Privacy Notice aims to explain how Veho
            Tech, Inc. (“Veho,” “we,” “us,” or “our”) handles personal information that we collect through our delivery
            service, including through the Veho app (collectively the “Service”). This policy does not describe the
            usage of our website (
            <a style={{ textDecoration: 'underline', color: '-webkit-link' }} href="https://shipveho.com">
              shipveho.com
            </a>
            ) generally.
          </Text>
          <Heading size="lg">Changes to this Notice</Heading>
          <Text marginY={4} fontSize={14}>
            We reserve the right to modify this Privacy Notice from time to time. In the event we make material changes,
            we will notify you by revising the “Notice Effective Date” at the top of this Privacy Notice and posting it
            on the Service or other appropriate means. Any modifications to this Privacy Notice will be effective upon
            our posting the modified version (or as otherwise indicated at the time of posting). In all cases, your use
            of the Service after the effective date of any modified Privacy Notice indicates your acknowledgment that
            the modified Privacy Notice applies to your interactions with the Service and our business. We encourage you
            to review our Privacy Notice whenever you use the Service to stay informed of any changes.
          </Text>
          <Heading size="lg">Information We Collect</Heading>
          <Text marginY={4} fontSize={14}>
            Veho may collect information about you directly, through the use of the Service, or from third parties.
            Please note that Veho does not use your personal information to independently market or advertise to you,
            and Veho does not collect financial information about you - your payment information remains with the
            retailer/subscription provider you ordered from.
          </Text>
          <Heading size="sm">Information you provide to us</Heading>
          <Text marginY={4} fontSize={14}>
            The types of information you may provide to us through the Service or otherwise includes:
          </Text>
          <VStack space={3} divider={<Divider />}>
            <HStack justifyContent="space-around">
              <Text marginRight="24px" fontSize={14}>
                Category of Personal Information
              </Text>
              <Text width="70%" fontSize={14}>
                Examples
              </Text>
            </HStack>
            <HStack justifyContent="space-around">
              <Text marginRight="24px" fontSize={14}>
                Contact Information{' '}
              </Text>
              <Text width="70%" fontSize={14}>
                This may include information such as your first and last name, email address, shipping or delivery
                addresses, and phone number
              </Text>
            </HStack>
            <HStack justifyContent="space-around">
              <Text marginRight="24px" fontSize={14}>
                Delivery Instructions{' '}
              </Text>
              <Text width="70%" fontSize={14}>
                This may include information such as how best to deliver your packages, including where to leave
                packages, access codes or other instructions
              </Text>
            </HStack>
            <HStack justifyContent="space-around">
              <Text marginRight="24px" fontSize={14}>
                Communications{' '}
              </Text>
              <Text width="70%" fontSize={14}>
                This includes information shared based on our exchanges with you, including when you contact us through
                the Service, when you give us a rating through the Service, or otherwise
              </Text>
            </HStack>
            <HStack justifyContent="space-around" marginBottom={4}>
              <Text marginRight="24px" fontSize={14}>
                Other data{' '}
              </Text>
              <Text width="70%" fontSize={14}>
                Information not specifically listed here, which we will use as described in this Privacy Notice or as
                otherwise disclosed at the time of collection
              </Text>
            </HStack>
          </VStack>
          <Heading size="lg">Third-party sources</Heading>
          <Text marginY={4} fontSize={14}>
            We may combine personal information we receive from you with personal information we obtain from other
            sources, such as:
          </Text>
          <ul style={{ fontSize: '14px' }}>
            <li>Public sources, such as Google geo coding data for location accuracy</li>
            <li>
              Private sources, such as the retailer/subscription business partners who contracted with Veho to deliver
              your packages
            </li>
          </ul>
          <Heading size="lg">Automatic data collection</Heading>
          <Text marginY={4} fontSize={14}>
            Since our Service is delivered to you via Simple Text Message or Multimedia Messages interactions, we do not
            automatically collect any other data than the information you put into these text messages or multimedia
            messages directly. We do not know any information about your device, operating system, configurations, etc.
          </Text>
          <Heading size="lg">Cookies and similar technologies</Heading>
          <Text marginY={4} fontSize={14}>
            There are no cookies being used when you interact with our Service via Simple Text Message or Multimedia
            Messages.
          </Text>
          <Heading size="lg">How We Use Your Information</Heading>
          <Text marginY={4} fontSize={14}>
            We may use your personal information for the following purposes or as otherwise described at the time of
            collection:
          </Text>
          <Heading size="sm">Service delivery and business operations</Heading>
          <Text marginTop={4} fontSize={14}>
            We may use your personal information to:
          </Text>
          <ul style={{ fontSize: '14px' }}>
            <li>provide, operate and improve the Service and our business;</li>
            <li>
              communicate with you about the Service, including by sending announcements, updates, and support and
              administrative messages;
            </li>
            <li>
              understand your needs and interests, and personalize your experience with the Service and our
              communications; and
            </li>
            <li>provide support for the Service, and respond to your requests, questions and feedback.</li>
          </ul>
          <Heading size="sm">Research and development</Heading>
          <Text marginY={4} fontSize={14}>
            We may use your personal information for research and development purposes, including to analyze and improve
            the Service and our business. As part of these activities, we may create aggregated, de-identified and/or
            anonymized data from personal information we collect. We make personal information into de-identified or
            anonymized data by removing information that makes the data personally identifiable to you. We may use this
            aggregated, de-identified or otherwise anonymized data and share it with third parties for our lawful
            business purposes, including to analyze and improve the Service and promote our business.
          </Text>

          <Heading size="sm">Compliance and protection</Heading>
          <Text marginY={4} fontSize={14}>
            We may use your personal information to:
          </Text>
          <ul style={{ fontSize: '14px' }}>
            <li>
              comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas or
              requests from government authorities;
            </li>
            <li>
              protect our, your or others' rights, privacy, safety or property (including by making and defending legal
              claims);{' '}
            </li>
            <li>
              audit our internal processes for compliance with legal and contractual requirements or our internal
              policies;
            </li>
            <li>enforce the terms and conditions that govern the Service; and</li>
            <li>
              prevent, identify, investigate and deter fraudulent, harmful, unauthorized, unethical or illegal activity,
              including cyberattacks and identity theft.
            </li>
          </ul>
          <Heading size="sm">With your consent</Heading>
          <Text marginY={4} fontSize={14}>
            In some cases, we may specifically ask for your consent to collect, use or share your personal information,
            such as when required by law.
          </Text>
          <Heading size="lg">Sharing of Your Data</Heading>
          <Text marginY={4} fontSize={14}>
            We may share your personal information with the following parties and as otherwise described in this Privacy
            Notice or at the time of collection.
          </Text>
          <ul style={{ fontSize: '14px' }}>
            <li>
              Service providers. Third parties that provide services on our behalf (such as delivery contractors and
              other providers) or help us operate the Service or our business (such as hosting, information technology,
              customer support, email delivery, marketing, consumer research and website analytics).
            </li>
            <li>
              Retailer/Subscription Partners. We may share your personal information with our partners or enable
              partners to collect information directly via our Service. For example, we may update the
              retailer/subscription provider about the status of your delivery and any issues experienced during the
              delivery process.
            </li>
            <li>
              Professional advisors. Professional advisors, such as lawyers, auditors, bankers and insurers, where
              necessary in the course of the professional services that they render to us.
            </li>
            <li>
              Authorities and others. Law enforcement, government authorities, and private parties, as we believe in
              good faith to be necessary or appropriate for the compliance and protection purposes described above.
            </li>
            <li>
              Business transferees. Acquirers and other relevant participants in business transactions (or negotiations
              of or due diligence for such transactions) involving a corporate divestiture, merger, consolidation,
              acquisition, reorganization, sale or other disposition of all or any portion of the business or assets of,
              or equity interests in Veho or our affiliates (including, in connection with a bankruptcy or similar
              proceedings).
            </li>
          </ul>
          <Heading size="lg">Links to Other Sites</Heading>
          <Text marginY={4} fontSize={14}>
            The Service may contain links to other websites, mobile applications, or other online services operated by
            third parties. If you click on a third-party link, you will be directed to that site. In addition, our
            content may be integrated into web pages or other online services that are not associated with us. These
            links and integrations are not an endorsement of, or representation that we are affiliated with, any third
            party. Note that these external sites are not operated by Veho and they may use personal information in
            accordance with their own privacy policies. Therefore, we strongly advise you to review the Privacy Policy
            of these websites. Veho does not have control over and assumes no responsibility for the content of these
            third-party sites or actions of any of these third parties.
          </Text>
          <Heading size="lg">Your Choices</Heading>
          <Text marginY={4} fontSize={14}>
            Upon service initiation, you have access to our Privacy Notice, and the option to contact your retail or
            subscription provider if you have concern about your deliver partner contacting you. In addition, you have
            the following choices:
          </Text>
          <ul style={{ fontSize: '14px' }}>
            <li>
              Access or update your information. You may review and update certain information by contacting us at{' '}
              <a href="mailto:privacy@shipveho.com" style={{ textDecoration: 'underline', color: '-webkit-link' }}>
                privacy@shipveho.com
              </a>
              .
            </li>
            <li>
              Declining to provide information. We need to collect personal information to provide certain services. If
              you do not provide the information we identify as required or mandatory, we may not be able to provide
              those services.
            </li>
          </ul>
          <Text>
            Please contact us at{' '}
            <a href="mailto:privacy@shipveho.com" style={{ textDecoration: 'underline', color: '-webkit-link' }}>
              privacy@shipveho.com
            </a>{' '}
            if you have any questions about any of the choices identified above.
          </Text>
          <Heading size="lg">Security</Heading>
          <Text marginY={4} fontSize={14}>
            We value your trust in providing us your Personal Information. As a result, we employ a number of technical,
            organizational and physical safeguards designed to protect the personal information we collect. However,
            security risk is inherent in all transmission of information over the Internet and other information
            technologies. This means we cannot guarantee the security of your personal information.
          </Text>
          <Heading size="lg">International Data Transfer</Heading>
          <Text marginY={4} fontSize={14}>
            We are headquartered in the United States and may use service providers that operate in other countries.
            Your personal information may be transferred to the United States or other locations where privacy laws may
            not be as protective as those in your state, province, or country.
          </Text>
          <Heading size="lg">Children</Heading>
          <Text marginY={4} fontSize={14}>
            The Veho Service is not intended for use by anyone under the age of 13 and we do not knowingly collect
            personal information from children under 13. If you are a parent or guardian of a child from whom you
            believe we have collected personal information, please contact us. If we learn that we have collected
            personal information through the Service from a child without the consent of the child’s parent or guardian
            as required by law, we will comply with applicable legal requirements to delete the information.
          </Text>
          <Heading size="lg">Contact Us</Heading>
          <Text marginY={4} fontSize={14}>
            If you would like to ask about, make a request relating to, or share feedback about how we process your
            personal information, please contact{' '}
            <a href="mailto:privacy@shipveho.com" style={{ textDecoration: 'underline', color: '-webkit-link' }}>
              privacy@shipveho.com
            </a>
            .
          </Text>
        </Box>
      </Box>
    </View>
  )
}

export default Privacy
