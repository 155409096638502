import Svg, { Path } from 'react-native-svg'

const PhoneFormPhoneNumberSVG = () => {
  return (
    <Svg width={80} height={80} fill="#a7ceeb" viewBox="0 0 256 256">
      <Path
        d="M224 64v128a8 8 0 0 1-8 8H82.5a8 8 0 0 0-5.15 1.88l-32.2 28.23A8 8 0 0 1 32 224V64a8 8 0 0 1 8-8h176a8 8 0 0 1 8 8Z"
        opacity={0.2}
      />
      <Path d="M216 48H40a16 16 0 0 0-16 16v160a15.85 15.85 0 0 0 9.24 14.5A16.13 16.13 0 0 0 40 240a15.89 15.89 0 0 0 10.25-3.78.69.69 0 0 0 .13-.11L82.5 208H216a16 16 0 0 0 16-16V64a16 16 0 0 0-16-16ZM40 224Zm176-32H82.5a16 16 0 0 0-10.3 3.75l-.12.11L40 224V64h176Zm-100-64a12 12 0 1 1 12 12 12 12 0 0 1-12-12Zm-44 0a12 12 0 1 1 12 12 12 12 0 0 1-12-12Zm88 0a12 12 0 1 1 12 12 12 12 0 0 1-12-12Z" />
    </Svg>
  )
}

export default PhoneFormPhoneNumberSVG
