import { gql } from 'graphql-tag'

import { orderFields } from './orderFields'

export const EDIT_ORDER = gql`
  mutation editOrder($addressInput: NewAddressInput, $orderId: String) {
    editOrder(addressInput: $addressInput, orderId: $orderId) {
      ...OrderFields
    }
  }
  ${orderFields}
`
