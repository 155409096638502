import Svg, { Path, Rect } from 'react-native-svg'

const CloseButtonSVG = () => {
  return (
    <Svg width={64} height={64} fill="none" viewBox="0 0 64 64">
      <Rect x="16" y="8" width="32" height="32" rx="16" fill="#21211F"></Rect>
      <Path
        fill="white"
        d="M37.0306 27.969C37.1715 28.1099 37.2506 28.301 37.2506 28.5002C37.2506 28.6995 37.1715 28.8906 37.0306 29.0315C36.8897 29.1724 36.6986 29.2515 36.4993 29.2515C36.3001 29.2515 36.109 29.1724 35.9681 29.0315L32 25.0621L28.0306 29.0302C27.8897 29.1711 27.6986 29.2503 27.4993 29.2503C27.3001 29.2503 27.109 29.1711 26.9681 29.0302C26.8272 28.8894 26.748 28.6983 26.748 28.499C26.748 28.2997 26.8272 28.1086 26.9681 27.9677L30.9375 23.9996L26.9693 20.0302C26.8285 19.8893 26.7493 19.6983 26.7493 19.499C26.7493 19.2997 26.8285 19.1086 26.9693 18.9677C27.1102 18.8268 27.3013 18.7477 27.5006 18.7477C27.6999 18.7477 27.891 18.8268 28.0318 18.9677L32 22.9371L35.9693 18.9671C36.1102 18.8262 36.3013 18.7471 36.5006 18.7471C36.6999 18.7471 36.8909 18.8262 37.0318 18.9671C37.1727 19.108 37.2519 19.2991 37.2519 19.4984C37.2519 19.6976 37.1727 19.8887 37.0318 20.0296L33.0625 23.9996L37.0306 27.969Z"
      />
    </Svg>
  )
}

export default CloseButtonSVG
