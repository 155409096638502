import { Center, HStack, Spinner } from 'native-base'

import { colors } from '../utils/theme/configureTheme'

export default function Loader() {
  return (
    <Center flex={1} px="3" style={{ minHeight: '50vh', margin: 'auto' }}>
      <HStack space={8} justifyContent="center" alignItems="center" padding="100">
        <Spinner size="lg" color={colors.primary[300]} />
      </HStack>
    </Center>
  )
}
