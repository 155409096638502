import Svg, { Path, Rect } from 'react-native-svg'

const ExpandButtonSVG = () => {
  return (
    <Svg width={33} height={33} fill="none" viewBox="0 0 33 33">
      <Rect x="0.5" y="0.0585938" width="32" height="32" rx="16" fill="#21211F"></Rect>
      <Path
        fill="white"
        d="M22.25 11.0586V14.0586C22.25 14.2575 22.171 14.4483 22.0303 14.5889C21.8897 14.7296 21.6989 14.8086 21.5 14.8086C21.3011 14.8086 21.1103 14.7296 20.9697 14.5889C20.829 14.4483 20.75 14.2575 20.75 14.0586V12.8711L18.2806 15.3411C18.1397 15.482 17.9486 15.5611 17.7494 15.5611C17.5501 15.5611 17.359 15.482 17.2181 15.3411C17.0772 15.2002 16.9981 15.0091 16.9981 14.8098C16.9981 14.6106 17.0772 14.4195 17.2181 14.2786L19.6875 11.8086H18.5C18.3011 11.8086 18.1103 11.7296 17.9697 11.5889C17.829 11.4483 17.75 11.2575 17.75 11.0586C17.75 10.8597 17.829 10.6689 17.9697 10.5283C18.1103 10.3876 18.3011 10.3086 18.5 10.3086H21.5C21.6989 10.3086 21.8897 10.3876 22.0303 10.5283C22.171 10.6689 22.25 10.8597 22.25 11.0586ZM14.7194 16.778L12.25 19.2461V18.0586C12.25 17.8597 12.171 17.6689 12.0303 17.5283C11.8897 17.3876 11.6989 17.3086 11.5 17.3086C11.3011 17.3086 11.1103 17.3876 10.9697 17.5283C10.829 17.6689 10.75 17.8597 10.75 18.0586V21.0586C10.75 21.2575 10.829 21.4483 10.9697 21.5889C11.1103 21.7296 11.3011 21.8086 11.5 21.8086H14.5C14.6989 21.8086 14.8897 21.7296 15.0303 21.5889C15.171 21.4483 15.25 21.2575 15.25 21.0586C15.25 20.8597 15.171 20.6689 15.0303 20.5283C14.8897 20.3876 14.6989 20.3086 14.5 20.3086H13.3125L15.7825 17.8392C15.9234 17.6983 16.0026 17.5072 16.0026 17.308C16.0026 17.1087 15.9234 16.9176 15.7825 16.7767C15.6416 16.6358 15.4505 16.5567 15.2512 16.5567C15.052 16.5567 14.8609 16.6358 14.72 16.7767L14.7194 16.778Z"
      />
    </Svg>
  )
}

export default ExpandButtonSVG
