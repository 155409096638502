import { Box, Text } from 'native-base'

export type InstructionsBadgeProps = {
  label: string | undefined | null
}

export const InstructionsBadge = ({ label }: InstructionsBadgeProps) => {
  return (
    <Box borderRadius={4} p={1} bgColor={'#dcebf7'} justifyContent={'center'} alignItems={'center'} flexShrink={1}>
      <Text
        textTransform={'uppercase'}
        color={'brand.softBlack'}
        fontFamily={'bodyBold'}
        fontSize={10}
        letterSpacing={'0.02em'}
        lineHeight={'130%'}
        textAlign={'left'}
        position={'relative'}
        flexWrap={'wrap'}
      >
        {label}
      </Text>
    </Box>
  )
}
